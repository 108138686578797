import { useState, useRef, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { setUploadedFile } from '../../../store/slices/deposit';
import './FileUpload.scss';

const MAX_FILE_SIZE_MB = 20;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;
const ALLOWED_FILE_TYPES = ['application/pdf',
  'image/heic',
  'image/jpeg',
  'image/png'];

const useFileUpload = () => {
  const dispatch = useDispatch();
  const [uploadedFile, setUploaded] = useState<File | undefined>(undefined);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [showError, setShowError] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const file = files[0];

      setUploaded(undefined);
      setShowError(false);

      if (file.size > MAX_FILE_SIZE_BYTES) {
        setShowError(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }

      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        setShowError(true);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        return;
      }

      setUploaded(file);
      setIsLoadingUpload(true);

      dispatch(setUploadedFile(file));
      setIsLoadingUpload(false);
    } else {
      setUploaded(undefined);
      setShowError(false);
    }
  };

  const handleUploadFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleResetFile = () => {
    dispatch(setUploadedFile(null));
    setUploaded(undefined);
    setShowError(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const Uploader = (
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileInputChange}
      accept=".pdf,.heic,.jpg,.jpeg,.png"
      className="file-uploader-input"
    />
  );

  return {
    Uploader,
    uploadedFile,
    isLoadingUpload,
    showError,
    setShowError,
    handleUploadFile,
    handleResetFile,
  };
};

export default useFileUpload;
