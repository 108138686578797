/* eslint-disable camelcase */
import qs from 'query-string';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { langToCountryCodeMap } from 'assets/countryCodeConfig';
import {
  getFormattedThemeName,
  getGroupAttributes,
  getIsSessionExpired,
  setPrevSessionDataToLocalStorage,
} from 'utils';
import { FlowTypes, SESSION_PROLONG_TIME, UIThemeTypes, appChannelTypes } from '../constants';
import { formatInitialQueryParams } from '../formatters';
import {
  PayHubDomainSessionsEnumsPlatformType,
  PlatformsViewport,
} from '../models';

export interface ParamsSnapshot {
  sessionId: string;
  preselectedPayGroupId: string | null;
  lang: string;
  brand: string;
  userId: string;
  clickstreamId: string;
  metrics: number;
  xChannel?: string;
  isApplePayAvailable?: boolean;
  isGPayAvailable?: boolean;
  parentWidth: number;
  parentHeight: number;
  selectedPayGroupId?: number;
  merchantRedirectUrl?: string;
  inPopUp?: boolean;
  isAlwaysClose?: boolean;
}

export type DisplaySelectListOptionsType = {
  [key: string]: boolean;
} | null;
export interface CommonState extends ParamsSnapshot {
  paymentFlow: FlowTypes;
  isSessionExpired: boolean;
  sessionExpirationTime: number;
  UITheme: UIThemeTypes;
  platform: PayHubDomainSessionsEnumsPlatformType;
  headersNavIcon?: string;
  headersNavIconPath?: string;
  appChannel: appChannelTypes;
  isStatusPageMode?: boolean;
  isCustomIframeMode?: boolean;
  userPhone: string;
  countryCode: string;
  initialTheme: string;
  isSubmitted: boolean;
  isLLA: boolean;
  needsInitRefetch: boolean;
  userBalance?: number;
  amount: string;
  growthbookFeatures?: string[];
  logoSizes: {
    [key: string]: number;
  };
  success_page_redirect_timeout: number;
  platformsViewport?: PlatformsViewport;
  merchantRedirectUrl?: string;
  merchantUrl?: string;
  merchantSuccessUrl?: string;
  merchantFailUrl?: string;
  merchantProcessingUrl?: string;
  merchantRedirectType?: string;
  notificationConnectionOpen: boolean;
  displaySelectListOptions: DisplaySelectListOptionsType;
}

export interface ReturnUrls {
  merchantUrl?: string;
  merchantSuccessUrl?: string;
  merchantFailUrl?: string;
  merchantProcessingUrl?: string;
  merchantRedirectType?: string;
}

export const initialState: CommonState = {
  paymentFlow: FlowTypes.deposit,
  UITheme: UIThemeTypes.modulor,
  appChannel: appChannelTypes.unknown,
  sessionId: '',
  lang: 'en',
  brand: '',
  clickstreamId: '',
  userId: '',
  userPhone: '',
  metrics: 0,
  parentWidth: window.innerWidth,
  parentHeight: window.innerHeight,
  preselectedPayGroupId: null,
  isSessionExpired: false,
  sessionExpirationTime: new Date().getTime() + SESSION_PROLONG_TIME.S,
  platform: PayHubDomainSessionsEnumsPlatformType.Desktop,
  headersNavIcon: '',
  headersNavIconPath: '',
  isStatusPageMode: false,
  isCustomIframeMode: false,
  needsInitRefetch: false,
  isLLA: false,
  countryCode: '',
  initialTheme: '',
  isSubmitted: false,
  userBalance: 0,
  amount: '0',
  success_page_redirect_timeout: 30,
  logoSizes: {
    height: 40,
    width: 40,
  },
  notificationConnectionOpen: false,
  displaySelectListOptions: null,
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setWidgetFlow(state, action: PayloadAction<FlowTypes>) {
      return {
        ...state,
        paymentFlow: action.payload,
      };
    },
    setAppChannel(state, action: PayloadAction<appChannelTypes>) {
      return {
        ...state,
        appChannel: action.payload,
      };
    },
    setParamsSnapshot(state, action: PayloadAction<string>) {
      return {
        ...state,
        ...formatInitialQueryParams(action.payload),
      };
    },
    setSessionExpired(state) {
      return {
        ...state,
        isSessionExpired: true,
      };
    },
    setIsLLA(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLLA: action.payload,
      };
    },
    setPlatform(state, action: PayloadAction<PayHubDomainSessionsEnumsPlatformType>) {
      return {
        ...state,
        platform: action.payload,
      };
    },
    setUserPhone(state, action: PayloadAction<string>) {
      return {
        ...state,
        userPhone: action.payload,
      };
    },
    setLogoSizes(state, action: PayloadAction<{ [key: string]: number }>) {
      return {
        ...state,
        logoSizes: action.payload,
      };
    },
    setGrowthbookFeatures(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        growthbookFeatures: action.payload,
      };
    },
    setCountryCode(state, action: PayloadAction<string>) {
      return {
        ...state,
        countryCode: action.payload,
      };
    },
    checkAndSetIsSessionExpired(state, { payload }) {
      const { isLLAPage } = payload;
      const expirationTime = new Date().getTime() + SESSION_PROLONG_TIME.L;
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const currentSessionId = state.sessionId || `${session_id}` || '';
      const isSessionExpired = !isLLAPage ? getIsSessionExpired({
        sessionIdSaved: currentSessionId,
        sessionExpirationTime: expirationTime,
      }) : false;

      setPrevSessionDataToLocalStorage({
        sessionId: currentSessionId,
        sessionExpirationTime: expirationTime,
      });

      return {
        ...state,
        isSessionExpired,
        sessionId: state.sessionId || currentSessionId,
        sessionExpirationTime: expirationTime,
      };
    },
    setSelectedPayGroupId(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedPayGroupId: action.payload,
      };
    },
    setHeadersNavIcon(state, action: PayloadAction<string>) {
      return {
        ...state,
        headersNavIcon: action.payload,
      };
    },
    setHeadersNavIconRedirectPath(state, action: PayloadAction<string>) {
      return {
        ...state,
        headersNavIconPath: action.payload,
      };
    },
    setIsStatusPageMode(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isStatusPageMode: action.payload,
      };
    },
    setIsCustomIframeMode(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isCustomIframeMode: action.payload,
      };
    },
    setInitialTheme(state, action: PayloadAction<string>) {
      return {
        ...state,
        initialTheme: action.payload,
      };
    },
    setIsSubmitted(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isSubmitted: action.payload,
      };
    },
    setUserBalance(state, action: PayloadAction<number | undefined>) {
      return {
        ...state,
        userBalance: action.payload,
      };
    },
    setAmount(state, action: PayloadAction<string>) {
      return {
        ...state,
        amount: action.payload,
      };
    },
    setSuccessPageRedirectTimeout: (state, action: PayloadAction<number>) => ({
      ...state,
      success_page_redirect_timeout: action.payload,
    }),
    setPlatformsViewport(state, action: PayloadAction<PlatformsViewport>) {
      state.platformsViewport = action.payload;
    },
    setReturnUrls(state, action: PayloadAction<ReturnUrls>) {
      const {
        merchantUrl,
        merchantSuccessUrl,
        merchantFailUrl,
        merchantProcessingUrl,
        merchantRedirectType,
      } = action.payload;

      return {
        ...state,
        merchantUrl,
        merchantSuccessUrl,
        merchantFailUrl,
        merchantProcessingUrl,
        merchantRedirectType,
      };
    },
    resetReturnUrls(state) {
      return {
        ...state,
        merchantUrl: undefined,
        merchantSuccessUrl: undefined,
        merchantFailUrl: undefined,
        merchantProcessingUrl: undefined,
        merchantRedirectType: undefined,
      };
    },
    resetMerchantRedirectUrl(state) {
      return {
        ...state,
        merchantRedirectUrl: undefined,
      };
    },
    notificationConnectionOpenOn(state) {
      return {
        ...state,
        notificationConnectionOpen: true,
      };
    },
    notificationConnectionOpenOff(state) {
      return {
        ...state,
        notificationConnectionOpen: false,
      };
    },
    setDisplaySelectListOptions(state, action: PayloadAction<DisplaySelectListOptionsType>) {
      return {
        ...state,
        displaySelectListOptions: action.payload,
      };
    },
    setNeedsInitRefetch(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        needsInitRefetch: action.payload,
      };
    },
  },
});

export const brandSelector = ({ common }: { common: CommonState }) => common.brand || 'COM';
export const initialBrandSelector = ({ common }: { common: CommonState }) => common.brand;
export const langSelector = ({ common }: { common: CommonState }) => common.lang;
export const clickstreamIdSelector = ({ common }: { common: CommonState }) => common.clickstreamId;
export const appChannelSelector = ({ common }: { common: CommonState }) => common.appChannel;
export const isSessionExpiredSelector = (
  { common }: { common: CommonState },
) => common.isSessionExpired;

export const sessionExpirationTimeSelector = (
  { common }: { common: CommonState },
) => common.sessionExpirationTime;

export const selectedPayGroupIdSelector = ({
  common,
}: { common: CommonState }) => common.selectedPayGroupId || getGroupAttributes().payGroupIdAttr;

export const sessionIdSelector = ({ common }: { common: CommonState }) => common.sessionId || '';
export const isApplePayAvailableSelector = ({
  common,
}: { common: CommonState }) => common.isApplePayAvailable || false;
export const isGooglePayAvailableSelector = ({
  common,
}: { common: CommonState }) => common.isGPayAvailable || false;

export const paymentFlowSelector = ({
  common,
}: { common: CommonState }) => common.paymentFlow;

export const userIdSelector = ({
  common,
}: { common: CommonState }) => common.userId;

export const platformSelector = ({
  common,
}: { common: CommonState }) => common.platform;

export const getHeadersNavIconSelector = ({
  common,
}: { common: CommonState }) => common.headersNavIcon || '';

export const getHeadersNavIconPathSelector = ({
  common,
}: { common: CommonState }) => common.headersNavIconPath || '';

export const isStatusPageModeSelector = ({
  common,
}: { common: CommonState }) => common.isStatusPageMode || false;

export const isCustomIframeModeSelector = ({
  common,
}: { common: CommonState }) => common.isCustomIframeMode || false;

export const userPhoneSelector = ({
  common,
}: { common: CommonState }) => common.userPhone;

export const logoSizesSelector = ({
  common,
}: { common: CommonState }) => common.logoSizes;

export const growthbookFeaturesSelector = ({
  common,
}: { common: CommonState }) => common.growthbookFeatures;

export const countryCodeSelector = ({
  common,
}: { common: CommonState }) => langToCountryCodeMap[common.countryCode] || common.countryCode;

export const initialThemeSelector = ({
  common,
}: { common: CommonState }) => {
  const theme = common.initialTheme;

  return getFormattedThemeName(theme);
};

export const isSubmittedSelector = ({
  common,
}: { common: CommonState }) => common.isSubmitted;

export const userBalanceSelector = ({
  common,
}: { common: CommonState }) => common.userBalance;

export const amountSelector = ({ common }: { common: CommonState }) => common.amount || '0';

export const successPageRedirectTimeoutSelector = ({ common }: { common: CommonState }) => (
  common.success_page_redirect_timeout
);

export const getPlatformsViewportSelector = ({
  common,
}: { common: CommonState }) => common.platformsViewport;

const merchantUrlSelector = ({
  common,
}: { common: CommonState }) => common.merchantUrl;

const merchantSuccessUrlSelector = ({
  common,
}: { common: CommonState }) => common.merchantSuccessUrl;

const merchantFailUrlSelector = ({
  common,
}: { common: CommonState }) => common.merchantFailUrl;

const merchantProcessingUrlSelector = ({
  common,
}: { common: CommonState }) => common.merchantProcessingUrl;

const merchantRedirectTypeSelector = ({
  common,
}: { common: CommonState }) => common.merchantRedirectType;

export const notificationConnectionOpenSelector = ({
  common,
}: { common: CommonState }) => common.notificationConnectionOpen;

export const isLLASelector = ({
  common,
}: { common: CommonState }) => common.isLLA;

export const displaySelectListOptionsSelector = ({
  common,
}: { common: CommonState }) => common.displaySelectListOptions;

export const needsInitRefetchSelector = ({
  common,
}: { common: CommonState }) => common.needsInitRefetch || false;

export const isInPopUpSelector = ({
  common,
}: { common: CommonState }) => Boolean(common.inPopUp);

export const isAlwaysCloseSelector = ({
  common,
}: { common: CommonState }) => Boolean(common.isAlwaysClose);

export const returnUrlsSelector = createSelector([
  merchantUrlSelector,
  merchantSuccessUrlSelector,
  merchantFailUrlSelector,
  merchantProcessingUrlSelector,
  merchantRedirectTypeSelector,
], (
  merchantUrl,
  merchantSuccessUrl,
  merchantFailUrl,
  merchantProcessingUrl,
  merchantRedirectType,
) => ({
  merchantUrl,
  merchantSuccessUrl,
  merchantFailUrl,
  merchantProcessingUrl,
  merchantRedirectType,
}));

export const merchantRedirectUrlSelector = ({
  common,
}: { common: CommonState }) => common.merchantRedirectUrl;

export const {
  setWidgetFlow,
  setAppChannel,
  setIsLLA,
  setParamsSnapshot,
  checkAndSetIsSessionExpired,
  setSessionExpired,
  setSelectedPayGroupId,
  setPlatform,
  setHeadersNavIcon,
  setHeadersNavIconRedirectPath,
  setIsStatusPageMode,
  setIsCustomIframeMode,
  setUserPhone,
  setCountryCode,
  setLogoSizes,
  setNeedsInitRefetch,
  setGrowthbookFeatures,
  setInitialTheme,
  setIsSubmitted,
  setUserBalance,
  setAmount,
  setSuccessPageRedirectTimeout,
  setPlatformsViewport,
  setReturnUrls,
  resetReturnUrls,
  resetMerchantRedirectUrl,
  notificationConnectionOpenOn,
  notificationConnectionOpenOff,
  setDisplaySelectListOptions,
} = commonSlice.actions;

export default commonSlice.reducer;
