/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState, useEffect } from 'react';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import {
  NavigationBar,
  Icon,
  Button,
  TextField,
  ListCell,
  RadioGroup,
  Radio,
  ListView,
} from '@modulor/react';
import { Option } from 'store/models';
import { getTranslationMessage, HEADER_ICON } from 'utils';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';
import CustomModal from '../CustomModal';

interface SelectFieldProps {
  name: string;
  label?: string;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
  options: Option[];
  isRequired?: boolean;
  placeHolder?: string;
}

export const SelectField:FC<SelectFieldProps> = (props) => {
  const {
    name,
    label = '',
    control,
    setValue,
    options,
    isRequired,
    placeHolder = '',
  } = props;
  const { t } = useTranslation();
  const [isInitialSelect, setInitialSelect] = useState(true);
  const [isOpen, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  useEffect(() => {
    if (options?.length === 1) {
      const value = {
        value: options[0].value,
        label: options[0].label,
      };
      setSelectedOption(value);
      setValue(name, value);
      setInitialSelect(false);
    }
  }, [options?.length]);

  const handleToggle = (value: boolean) => () => {
    setOpen(value);
  };

  const onSelectOption = (card: Option): void => {
    const selectedValue = {
      value: card.value,
      label: card.label,
    };
    setSelectedOption(selectedValue);
  };

  const handleConfirm = () => {
    setValue(name, selectedOption);
    setInitialSelect(false);
    setOpen(false);
  };

  const handleBack = () => {
    if (isInitialSelect) {
      setSelectedOption(null);
    }
    setOpen(false);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          message: 'PH.ERROR.FIELD_REQUIRED',
          value: !!isRequired,
        },
      }}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error ? getTranslationMessage({
          t,
          value: fieldState.error?.message,
        }) : '';
        return (
          <>
            <CustomModal
              isPopupView
              open={isOpen}
              openModal={isOpen}
              onShadowClick={handleToggle(false)}
              onSwipeDown={handleToggle(false)}
              header={(
                <NavigationBar
                  transparent
                  title={placeHolder}
                  left={(
                    <Icon
                      name={HEADER_ICON.back}
                      onClick={handleBack}
                    />
                          )}
                />
                  )}
              buttonNumber={1}
              buttons={(
                <Button
                  variant="primary"
                  onClick={() => {
                    field.onChange(selectedOption);
                    handleConfirm();
                  }}
                  disabled={!selectedOption?.value}
                >
                  {t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.CONFIRM`)}
                </Button>
                  )}
            >
              <div className="content-page-wrapper">
                <ListView className="cards-container-wrapper">
                  <RadioGroup onChange={() => {}}>
                    {options?.map((option) => (
                      <ListCell
                        key={option.value}
                        className="card-box"
                        text={option.label}
                        onClick={() => onSelectOption(option)}
                        content={(
                          <Radio
                            className="action__in-list-cell radio-btn"
                            value={option.value || ''}
                            checked={option.value === selectedOption?.value}
                          />
                        )}
                      />
                    ))}
                  </RadioGroup>
                </ListView>
              </div>
            </CustomModal>
            <TextField
              {...field}
              name={name}
              value={field.value?.label}
              placeholder={placeHolder}
              label={label}
              type="text"
              onClick={handleToggle(true)}
              error={errorMessage}
              icon="chevron_up_chevron_down"
              readOnly
            />
          </>
        );
      }}
    />
  );
};
