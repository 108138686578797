/* eslint-disable camelcase */
import qs from 'query-string';
import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import {
  PayHubCashierContractsPayoutsInitPayoutGroupsResponse as InitSuccess,
  PayHubWidgetContractsPayoutsSelectPayoutGroupResponse as WithdrawalMethodInfoResponse,
  PayHubCashierContractsPaymentsSubmitPaymentGroupRequest,
  PayHubWidgetContractsSubmitPayoutResponse,
} from 'store/models';
import {
  formWithdrawalGroups,
  formWithdrawalMethodInfo, formWithdrawalRequiredFields, UIPayout,
} from 'store/formatters';
import { setReqHeaders } from 'utils/common';

export interface CustomServerError {
  status: Pick<FetchBaseQueryError, 'status'> | string | number;
  errorMessage?: string;
  errorCode?: number;
}

type ExtendedFetchBaseQueryError = FetchBaseQueryError & {
  data?: {
    error_message: string;
    error_code: number;
  };
};

export interface WithdrawalSubmitRequestProps {
  payGroupId: number;
  requestBody: PayHubCashierContractsPaymentsSubmitPaymentGroupRequest;
}

export type GetWithdrawalMethodInfoRequestProps = {
  payGroupId: number;
  currency: string;
};

const CASHIER_SERVICE_ENV = process.env.NODE_ENV === 'production' ? '/api/cashier-service/' : '/cash-ph20-service/';
const CASHIER_API = `${CASHIER_SERVICE_ENV}v1.0`;
const INIT_TAG = 'init_withdrawal';
export const SELECT_TAG = 'select_withdrawal';

export const withdrawalAPI = createApi({
  reducerPath: 'withdrawalAPI',
  tagTypes: [INIT_TAG,
    SELECT_TAG],
  baseQuery: fetchBaseQuery({
    baseUrl: CASHIER_API,
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      getWithdrawalMethods: builder.query<UIPayout, void>({
        query: () => ({
          url: '/payouts/groups/init',
        }),
        keepUnusedDataFor: Infinity,
        providesTags: [INIT_TAG],
        transformResponse: (response: InitSuccess) => formWithdrawalGroups(response),
      }),
    };
  },
});

export const extendedWithdrawalAPI = withdrawalAPI.injectEndpoints({
  endpoints: (builder) => ({
    getWithdrawalMethodInfo: builder.query<
    WithdrawalMethodInfoResponse,
    GetWithdrawalMethodInfoRequestProps
    >({
      query: ({ payGroupId, currency }) => ({
        url: `/payouts/groups/${payGroupId}/select`,
        method: 'POST',
        body: {
          currency,
        },
      }),
      keepUnusedDataFor: Infinity,
      providesTags: (result, error, { payGroupId }) => [
        {
          type: SELECT_TAG, id: payGroupId,
        },
      ],
      transformResponse: (
        response: WithdrawalMethodInfoResponse,
        meta,
        { payGroupId },
      ) => formWithdrawalMethodInfo(
        response,
        formWithdrawalRequiredFields,
        payGroupId,
      ) as WithdrawalMethodInfoResponse,
    }),
    submitWithdrawal: builder.query<PayHubWidgetContractsSubmitPayoutResponse, WithdrawalSubmitRequestProps>({
      query: ({
        payGroupId,
        requestBody,
      }) => ({
        url: `payouts/groups/${payGroupId}/submit`,
        method: 'POST',
        body: requestBody,
      }),
      transformErrorResponse: (response: ExtendedFetchBaseQueryError): CustomServerError => ({
        status: response?.status,
        errorMessage: response?.data?.error_message,
        errorCode: response?.data?.error_code,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetWithdrawalMethodsQuery,
  useLazyGetWithdrawalMethodsQuery,
} = withdrawalAPI;
export const {
  useGetWithdrawalMethodInfoQuery,
  useLazyGetWithdrawalMethodInfoQuery,
  useLazySubmitWithdrawalQuery,
} = extendedWithdrawalAPI;

export const { endpoints } = withdrawalAPI;

export default withdrawalAPI.reducer;
