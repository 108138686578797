import { FC, useEffect } from 'react';
import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { TextField } from '@modulor/react';
import { Option } from 'store/models';
import { useAppDispatch } from 'store/hooks';
import { setDisplaySelectListOptions } from 'store/slices/global';
import './SelectField.scss';

interface SelectFieldProps {
  name: string;
  label?: string;
  control: Control;
  setValue: UseFormSetValue<FieldValues>;
  options: Option[];
  isRequired?: boolean;
  placeHolder?: string;
}

export const SelectField:FC<SelectFieldProps> = (props) => {
  const {
    name,
    label = '',
    control,
    setValue,
    options,
    placeHolder = '',
    isRequired = false,
  } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (options?.length === 1) {
      const value = {
        value: options[0].value,
        label: options[0].label,
      };
      setValue(name, value);
    }
  }, [options?.length]);

  const handleToggle = (value: boolean) => () => {
    dispatch(setDisplaySelectListOptions({
      [name]: value,
    }));
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: {
          message: 'PH.ERROR.FIELD_REQUIRED',
          value: !!isRequired,
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          dataTestId="custom-select"
          className="select-field"
          clearVisible={false}
          name={name}
          value={field.value?.label}
          placeholder={placeHolder}
          label={label}
          type="text"
          onClick={handleToggle(true)}
          icon="chevron_up_chevron_down"
          readOnly
        />
      )}
    />
  );
};
