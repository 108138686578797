/* eslint-disable max-len */
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import { UIPaymentGroups, UIPayoutGroups } from 'store/formatters';
import {
  PayHubDomainSessionsEnumsPlatformType,
  PayHubDomainCashierEnumsCashierViewDataType, PayHubCashierContractsViewDataResponse, MerchantUserFeeResponce,
} from 'store/models';
import { LINK_TARGET } from 'utils/constants';
import {
  getLinkTargetType, getTranslationMessage,
  isContentPageUrl, transformContentUrl,
} from 'utils';
import { openContentPageUrl } from '../store/slices/contentPage';
import { FlatStringObject } from '../commonTypes';
import { NAMESPACES } from '../services/constants';
import { getMerchantUserFeeTranslation } from './fee';

interface NavigateForInfoProps {
    isContentPage: boolean;
    isSelfTarget: boolean;
    dispatch: Dispatch;
    linkTarget: LINK_TARGET;
    descriptionUrl: string;
  }

export interface SelectInfoMethodProps {
    isSelfTarget: boolean;
    descriptionUrl?: string;
    dispatch: Dispatch;
    platform?: PayHubDomainSessionsEnumsPlatformType;
  }

export interface SelectActionDepositMethodProps {
    method: UIPaymentGroups;
    navigate: NavigateFunction;
    dispatch: Dispatch;
    flowType: string;
  }

export interface SelectActionWithdrawalMethodProps {
    method: UIPayoutGroups;
    navigate: NavigateFunction;
    dispatch: Dispatch;
    flowType: string;
  }

export enum GROUPS { Popular, LostProfit, Regular, Disabled}

export type InitGroupList<T extends UIPaymentGroups | UIPayoutGroups> = {
  [p in GROUPS]: T[];
};

export interface GroupList<T extends UIPaymentGroups | UIPayoutGroups> {
  name: GROUPS;
  list: T[];
}

export const getTwoMethodColumns = <T>(methods: T[]) => {
  if (!methods?.length) {
    return [];
  }

  return methods.reduce((acc: [T[], T[]], current, index) => {
    acc[index % 2].push(current);

    return acc;
  }, [
    [],
    [],
  ]);
};

export const navigateForInfo = (props: NavigateForInfoProps): void => {
  const {
    isContentPage,
    isSelfTarget,
    linkTarget,
    dispatch,
    descriptionUrl,
  } = props;
  const navigateMap = {
    _self: (): void => document.location.assign(descriptionUrl),
    _blank: (): WindowProxy | null => window.open(descriptionUrl, '_blank'),
  };

  if (isContentPage && isSelfTarget) {
    dispatch(openContentPageUrl(descriptionUrl));
    return;
  }

  navigateMap[linkTarget]();
};

export const selectInfoMethod = (props: SelectInfoMethodProps): void => {
  const {
    descriptionUrl,
    isSelfTarget,
    dispatch,
    platform,
  } = props;

  if (!descriptionUrl) {
    return;
  }

  const transformedUrl = transformContentUrl(descriptionUrl);
  const isContentPage = isContentPageUrl(transformedUrl);
  const linkTarget = getLinkTargetType({
    platform,
    isSelfTarget,
  });

  navigateForInfo({
    isContentPage,
    isSelfTarget,
    dispatch,
    linkTarget,
    descriptionUrl: transformedUrl,
  });
};

type TFunction = (t: string, params?: FlatStringObject) => string;
interface FormMethodSubTitleProps {
  t: TFunction;
  processingTime?: string;
  fee: MerchantUserFeeResponce | string;
}

export const formMethodSubTitle = (props: FormMethodSubTitleProps): string => {
  const {
    t,
    processingTime = '',
    fee,
  } = props;
  const separator: string = processingTime && ((typeof fee === 'object' && fee.percentage) || typeof fee === 'string') ? ' • ' : '';

  const feeText = getMerchantUserFeeTranslation(fee, t);

  const processingTimeText = getTranslationMessage({
    t,
    value: processingTime,
  }) || '';

  return [
    t(processingTimeText),
    feeText,
  ].join(separator);
};

export const getMethodGroups = <T extends UIPaymentGroups | UIPayoutGroups>(methods: T[]): GroupList<T>[] => {
  if (!methods.length) {
    return [];
  }

  const init: InitGroupList<T> = {
    [GROUPS.Popular]: [],
    [GROUPS.LostProfit]: [],
    [GROUPS.Regular]: [],
    [GROUPS.Disabled]: [],
  };

  const methodObjects = methods.reduce((acc, method) => {
    if (!method.enabled || method?.restrictedUntil) {
      acc[GROUPS.Disabled].push(method);
      return acc;
    }

    if (method.isPopular) {
      acc[GROUPS.Popular].push(method);
      return acc;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (method?.isLostProfit) {
      acc[GROUPS.LostProfit].push(method);
      return acc;
    }

    if (method) {
      acc[GROUPS.Regular].push(method);
      return acc;
    }

    return acc;
  }, init);

  return [
    {
      name: GROUPS.Popular, list: methodObjects[GROUPS.Popular],
    },
    {
      name: GROUPS.LostProfit, list: methodObjects[GROUPS.LostProfit],
    },
    {
      name: GROUPS.Regular, list: methodObjects[GROUPS.Regular],
    },
    {
      name: GROUPS.Disabled, list: methodObjects[GROUPS.Disabled],
    },
  ];
};

export const getGroupsCount = (methodGroups: GroupList<UIPaymentGroups | UIPayoutGroups>[]) => {
  const notEmptyGroups = methodGroups.filter((group) => group.list && group.list.length > 0);
  return notEmptyGroups.length;
};

export const getAvailableMethods = <T extends UIPaymentGroups | UIPayoutGroups>(methods: T[]) => {
  if (!methods?.length) {
    return [];
  }

  return methods.filter((method) => (method.enabled && !method?.restrictedUntil));
};

export const getDisabledMethods = <T extends UIPaymentGroups | UIPayoutGroups>(
  methods: T[],
  isDesktopLayout: boolean,
) => {
  if (!methods?.length) {
    return {};
  }

  const disabledMethodList = methods.filter((method) => (!method.enabled && !method?.restrictedUntil));
  const disabledMethodListUntil = methods.filter((method) => (!!method?.restrictedUntil));

  const disabledMethodListResult = isDesktopLayout ? getTwoMethodColumns<T>(disabledMethodList) : [disabledMethodList];
  const disabledMethodListUntilResult = isDesktopLayout ? getTwoMethodColumns<T>(disabledMethodListUntil) : [disabledMethodListUntil];

  return {
    disabledMethodList: disabledMethodListResult,
    disabledMethodListUntil: disabledMethodListUntilResult,
  };
};

export const getRestrictedUntilText = (t: TFunction, restrictedUntil?: string) => {
  if (!restrictedUntil) {
    return {
      key: '',
      message: '',
    };
  }

  const now = (new Date()).getTime();
  const restrictedTime = new Date(restrictedUntil);
  const dif = restrictedTime.getTime() - now;
  const difAsDate = new Date(restrictedTime.getTime() - now);
  const DD = restrictedTime.getDate().toString().padStart(2, '0');
  const MM = (restrictedTime.getMonth() + 1).toString().padStart(2, '0');
  const hh = restrictedTime.getHours().toString().padStart(2, '0');
  const mm = restrictedTime.getMinutes().toString().padStart(2, '0');
  const mmDif = difAsDate.getMinutes().toString().padStart(2, '0');
  let resctrictedKey = '';
  let time = '';
  const result = {
    key: '',
    message: '',
  };

  if (dif > (3600000 * 12)) {
    resctrictedKey = `${NAMESPACES.PW_KEYS}:CASHIER.RESTRICT_UNTIL_TEXT_BIG`;
    time = `${DD}.${MM} ${hh}:${mm}`;
  } else if (dif < (3600000 * 12) && dif > 3600000) {
    resctrictedKey = `${NAMESPACES.PW_KEYS}:CASHIER.RESTRICT_UNTIL_TEXT_MID`;
    time = `${hh}:${mm}`;
  } else if (dif < 3600000) {
    resctrictedKey = `${NAMESPACES.PW_KEYS}:CASHIER.RESTRICT_UNTIL_TEXT_SMALL`;
    time = `${mmDif}`;
  }

  result.key = resctrictedKey;
  result.message = t(resctrictedKey, {
    time,
  });

  return result;
};

export const getViewData = (data?: PayHubCashierContractsViewDataResponse[] | null) => {
  if (!data?.length) {
    return {};
  }

  const additionalViewData = data?.filter((obj) => obj.type === PayHubDomainCashierEnumsCashierViewDataType.AdditionalInfo);
  const viewData = data?.filter((obj) => obj.type !== PayHubDomainCashierEnumsCashierViewDataType.AdditionalInfo);

  return {
    additionalViewData,
    viewData,
  };
};
