import qs from 'query-string';
import { ParamsSnapshot } from '../slices/global';

const stringOrEmpty = (v: unknown): string => (typeof v === 'string' ? v : '');
const stringToNumber = (v: unknown): number => {
  if (typeof v === 'string') {
    const numbered = Number(v);
    return Number.isNaN(numbered) ? 0 : numbered;
  }

  return 0;
};

export const getLangBrand = (param: string): [string, string] => {
  let lang = 'en';
  let brand = '';

  try {
    const [paramLang, paramBrand] = param.split('-');
    if (typeof param === 'string') {
      if (paramLang) {
        lang = paramLang;
      }
      if (paramBrand) {
        brand = paramBrand;
      }
    }
  } catch { /* empty */ }

  return [
    lang,
    brand,
  ];
};

export const formatInitialQueryParams = (query: string): ParamsSnapshot => {
  const {
    // eslint-disable-next-line camelcase
    session_id: sId,
    clstrmid: clickstreamId,
    cashier_session_id: oldSId,
    // eslint-disable-next-line camelcase
    pay_group,
    lang: paramLang,
    user,
    metrics,
    width,
    height,
    merchant_redirect_url: merchantRedirectUrl,
    in_pop_up: inPopUp,
    close_icon: isAlwaysClose,
  } = qs.parse(query);

  const parsedParamLang = Array.isArray(paramLang) ? paramLang[0] : paramLang;

  const sessionId = (sId || oldSId || '') as string;
  const [lang, brand] = getLangBrand(parsedParamLang as string);

  return {
    sessionId,
    lang,
    brand,
    preselectedPayGroupId: stringOrEmpty(pay_group),
    userId: stringOrEmpty(user),
    clickstreamId: stringOrEmpty(clickstreamId),
    metrics: stringToNumber(metrics),
    parentWidth: width ? Number(width) : window.innerWidth,
    parentHeight: height ? Number(height) : window.innerHeight,
    merchantRedirectUrl: stringOrEmpty(merchantRedirectUrl),
    inPopUp: Boolean(inPopUp),
    isAlwaysClose: Boolean(isAlwaysClose),
  };
};

export const applyDateMask = (value: string, format: string): string => {
  let newValue = value.replace(/\D/g, '').toString();
  const delimiter = format.replace(/\w/g, '').toString()[0];
  const yearFirstFlow = (/^\w{4}/g).test(format);

  if (yearFirstFlow) {
    if (newValue.length > 4 && newValue.length < 6) {
      newValue = `${newValue.slice(0, 4)}${delimiter}${newValue.slice(4, 6)}`;
    } else if (newValue.length >= 6) {
      newValue = `${newValue.slice(0, 4)}${delimiter}${newValue.slice(4, 6)}${delimiter}${newValue.slice(6, 8)}`;
    }
  } else if (newValue.length >= 3 && newValue.length <= 4) {
    newValue = `${newValue.slice(0, 2)}${delimiter}${newValue.slice(2)}`;
  } else if (newValue.length >= 5) {
    newValue = `${newValue.slice(0, 2)}${delimiter}${newValue.slice(2, 4)}${delimiter}${newValue.slice(4, 8)}`;
  }
  return newValue;
};
