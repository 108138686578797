import { FC, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { useLazyRequestLLAStatusQuery } from 'store/services/pageLlaAPI';
import { useLazyRequest3dsQuery } from 'store/services/page3dsAPI';
import { setSessionExpired } from 'store/slices/global';
import { CustomServerError } from 'store/services/depositAPI';
import ModulorLoader from 'Modulor/components/ModulorLoader';
import { getParamsForLLARequest } from './utils';
import { useNavigateWithSearch } from '../hooks';

import './LLAStatus.scss';

interface PageLLAStatusProps {
  is3DS?: boolean;
}

export const PageLLAStatus:FC<PageLLAStatusProps> = ({ is3DS = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithSearch();
  const [triggerLLA, llaState] = useLazyRequestLLAStatusQuery();
  const [trigger3DS, threeDSState] = useLazyRequest3dsQuery();

  const { error, isSuccess, data } = is3DS ? threeDSState : llaState;

  useEffect(() => {
    const params = getParamsForLLARequest();

    if (typeof params === 'boolean' || params.isExpired) {
      dispatch(setSessionExpired());
      return;
    }
    if (is3DS) {
      trigger3DS(params.paymentParams);
    } else {
      triggerLLA();
    }
  }, []);

  useEffect(() => {
    if (error && (error as CustomServerError)?.error_code === 17) {
      dispatch(setSessionExpired());
      return;
    }
    if (error) {
      navigate('/deposit/deposit-error/');
    }
  }, [error]);

  useEffect(() => {
    const url = data?.url || data?.request_url;
    if (isSuccess && url) {
      window.location.replace(url);
      return;
    }
    if (isSuccess && (data.result_status === 'success' || data.status === 'success')) {
      navigate('/deposit/deposit-success/');
      return;
    }

    if (isSuccess && (data.result_status === 'failed' || data.status === 'failed')) {
      navigate('/deposit/deposit-success/');
    }
  }, [data,
    isSuccess]);

  return <ModulorLoader />;
};

export default PageLLAStatus;
