import { useEffect, FC } from 'react';
import clx from 'classnames';
import IntegrationCommunication from 'services/integration-communication';
import { Button, Typography } from '@modulor/react';
import { useAppSelector } from 'store/hooks';
import { isInPopUpSelector, platformSelector } from 'store/slices/global';
import ProcessingStatusIcon from 'assets/ProcessingStatusIcon';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';
import { statusRedirectOnProcessing } from '../components/SubmitForm/utils';

type TransactionProcessingProps = {
  stopTimer: boolean;
  urlToRedirect: string;
};

let timer: NodeJS.Timeout;

export const TransactionProcessing: FC<TransactionProcessingProps> = (props) => {
  const {
    stopTimer,
    urlToRedirect,
  } = props;
  const { t } = useTranslation();
  const platform = useAppSelector(platformSelector);
  const isOpenedInPopup = useAppSelector(isInPopUpSelector);
  const redirectCallback = () => {
    const merchantNode = document.querySelector('#iframe-host');
    clearTimeout(timer);

    if (!merchantNode) {
      statusRedirectOnProcessing({
        platform,
        urlToRedirect,
        isOpenedInPopup,
      });
      return;
    }

    timer = setTimeout(redirectCallback, 30000);
  };

  useEffect(() => {
    IntegrationCommunication.sendMessage({
      id: 'depositResult',
      value: 'success',
    });
    timer = setTimeout(redirectCallback, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (stopTimer) {
      clearTimeout(timer);
    }
  }, [stopTimer]);

  return (
    <section
      className={clx('status', 'transaction-processing')}
    >
      <div className="status-icon-wrapper">
        <ProcessingStatusIcon />
      </div>
      <div className="status-text-wrapper">
        <Typography
          variant="title-2-semibold"
        >
          {t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.STATUS_PAGE.PROCESSING.TITLE`)}
        </Typography>
      </div>
      <div className="bottom-button-wrapper">
        <Button
          className="bottom-button"
          onClick={redirectCallback}
        >
          {t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.BACK_TO_GAME`)}
        </Button>
      </div>
    </section>
  );
};
