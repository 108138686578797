import { createSlice } from '@reduxjs/toolkit';

export interface SubmitFormSliceState {
  isRedirectFailed: boolean;
}

export const initialState: SubmitFormSliceState = {
  isRedirectFailed: true,
};

const submitFormSlice = createSlice({
  name: 'submitForm',
  initialState,
  reducers: {
    resetSubmitRedirectError() {
      return {
        isRedirectFailed: false,
      };
    },
  },
});

export const submitRedirectErrorSelector = ({
  submitForm,
}: { submitForm: SubmitFormSliceState }) => submitForm.isRedirectFailed;

export const {
  resetSubmitRedirectError,
} = submitFormSlice.actions;

export default submitFormSlice.reducer;
