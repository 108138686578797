import dayjs from 'dayjs';
import { MouseEvent } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { isEmpty } from 'ramda';
import { FieldValues } from 'react-hook-form';
import { UIPayment, UIPaymentGroups } from 'store/formatters';
import { setSelectedPayGroupId } from 'store/slices/global';
import { setCurrentMethod } from 'store/slices/deposit';
import {
  CurrencyDisplaySetting,
  LostProfitInfo,
  MerchantUserFeeResponce,
  Option,
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ApiParameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType,
  PayHubEnumsCashierMethodParamType,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType,
  PayHubDomainSessionsEnumsPlatformType,
  PayHubWidgetContractsUserCardResponse,
  ProcessingCurrency,
  SelectedCardInfo,
  UserBonusInfo,
} from 'store/models';
import { NavigateFunction } from 'react-router-dom';
import {
  formatAmount,
  getTranslationMessage,
  SelectActionDepositMethodProps,
  setNullToNotRequiredFields,
  softLocalStorageSet,
} from 'utils';
import { getInitialSelectedAmountIndex } from 'Modulor/components/utils';
import { getMerchantUserFeeTranslation } from 'utils/fee';
import { FlowTypes, SELECTED_METHOD_ID } from 'store/constants';
import { getFormattedAmountWithCurrency, getPrettierFormatNumber } from 'utils/dataTransform';
import { redirectToSelectedMethod } from '../routerUtils';
import { OTPStrategy } from '../SubmitStrategy/OTPStrategy';
import { PaymentProcessStrategy } from '../SubmitStrategy/PaymentProcessStrategy';
import { DynamicTypeObject, FlatStringObject, TFunction } from '../../commonTypes';
import { SelectedCardType } from '../../store/slices/userCard';
import { NAMESPACES } from '../../services/constants';

export const selectActionMethod = (props: SelectActionDepositMethodProps): void => {
  const {
    method,
    navigate,
    dispatch,
    flowType,
  } = props;

  dispatch(setCurrentMethod(method));
  dispatch(setSelectedPayGroupId(method.id));
  softLocalStorageSet(SELECTED_METHOD_ID, JSON.stringify(method.id));
  redirectToSelectedMethod(method.id, navigate, flowType);
};

interface SelectDepositMethodHandlerProps {
  method: UIPaymentGroups;
  platform?: PayHubDomainSessionsEnumsPlatformType;
  dispatch: Dispatch;
  navigate: NavigateFunction;
  flowType: FlowTypes;
  descriptionUrl?: string;
}
export const selectDepositMethodHandler = (
  props: SelectDepositMethodHandlerProps,
  event?: MouseEvent,
): boolean | undefined => {
  const {
    method,
    dispatch,
    navigate,
    flowType,
  } = props;
  if (!method.enabled) {
    event?.stopPropagation();
    event?.preventDefault();
    return false;
  }

  if (method.payGroupActionType === PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.Activity) {
    dispatch(setSelectedPayGroupId(method.id));
    softLocalStorageSet(SELECTED_METHOD_ID, JSON.stringify(method.id));
    selectActionMethod({
      method,
      dispatch,
      navigate,
      flowType,
    });
  }

  return undefined;
};

interface FormMethodSubTitleProps {
  t: TFunction;
  processingTime?: string;
  isIncludeBonus?: boolean;
  fee: MerchantUserFeeResponce | string;
  lostProfitInfo?: LostProfitInfo | null;
}

export const formMethodSubTitle = (props: FormMethodSubTitleProps): string => {
  const {
    t,
    processingTime = '',
    fee,
    lostProfitInfo,
    isIncludeBonus = false,
  } = props;

  const separator = ' • ';
  const bonusKey = isIncludeBonus ? `${NAMESPACES.PW_KEYS}:PH.BONUS_INCLUDED` : '';
  const bonusText = t(bonusKey);
  const processingTimeText = getTranslationMessage({
    t,
    value: processingTime,
  }) || '';
  const feeText = getMerchantUserFeeTranslation(fee, t);

  const subTitleArr = lostProfitInfo ? [
    t(lostProfitInfo.text, lostProfitInfo.props as FlatStringObject),
    t(bonusText),
  ] : [
    t(processingTimeText),
    feeText,
    t(bonusText),
  ];

  return subTitleArr.filter((element) => Boolean(element)).join(separator);
};

export const buildCardsData = (card: SelectedCardType | null | undefined) => {
  const cardId = card?.id ? String(card.id) : '';
  return {
    card_id: card?.isNew ? undefined : cardId,
    card_bin: card?.bin,
  };
};

const prepareExpirationDate = (expMonth: number | null | undefined, expYear: number | null | undefined): string => {
  if (expMonth && expYear) {
    const croppedExpYear = expYear % 100;
    const formattedExpMonth = `${expMonth}`.padStart(2, '0');
    return `${formattedExpMonth}/${croppedExpYear}`;
  }
  return '';
};

export const normalizeParamWithSpaces = (field: string) => {
  const trimmedName = field.trim();
  return trimmedName.replace(/\s+/g, ' ');
};

export const buildDefaultCheckoutValues = (
  card?: PayHubWidgetContractsUserCardResponse | null,
  amount = '',
) => ({
  expirationDate: prepareExpirationDate(card?.exp_month, card?.exp_year),
  amount,
  [ApiParameter.CardCardholderName]: normalizeParamWithSpaces(card?.cardholder_name || ''),
  [ApiParameter.CardCvv]: '',
  [ApiParameter.CardId]: card?.id,
  save_card: true,
  card_pan: '',
});

export const updateFieldsDataStructure = (fields: FieldValues, cardInfo: SelectedCardInfo) => (
  fields.filter((field: Parameter) => !(
    field.extendedType === ApiParameter.CardPan && cardInfo.selectedCard?.last4)));

export const prepareFieldsDataForSubmit = (
  fields: FieldValues,
  selectedCard: SelectedCardType | null,
  requiredFields: Parameter[],
  dirtyFields?: Partial<Record<keyof FieldValues, boolean>>,
) => {
  const preparedFields: DynamicTypeObject = setNullToNotRequiredFields(requiredFields, fields, dirtyFields);

  const result: Record<string, string | number | unknown> = {};
  Object.keys(preparedFields).forEach((item) => {
    const fieldValue: string | number | unknown | Option = preparedFields[item];
    const fieldType = requiredFields.find((current) => current.name === item)?.type;

    if (fieldType === 'date') {
      result[item] = dayjs(fieldValue as Date).toISOString();
    } else if (fieldValue && typeof fieldValue === 'object' && 'value' in fieldValue) {
      result[item] = fieldValue.value;
    } else {
      result[item] = fieldValue;
    }
  });

  if (selectedCard && !isEmpty(selectedCard)) {
    return {
      ...result,
      ...buildCardsData(selectedCard),
    };
  }

  return result;
};

export const excludeOTPField = (isOTPEnabled: boolean, fields: Parameter[]) => (!isOTPEnabled
  ? fields
  : fields.filter(({ isOtpRequisites }) => !isOtpRequisites));

export const getSubmitStrategy = (isOTPEnabled: boolean, isCheckout: boolean, cardId?: number) => {
  if (isOTPEnabled) {
    return new OTPStrategy();
  }

  return new PaymentProcessStrategy();
};

export type Amount = string | number;

interface GetBonusTextProps {
  t: TFunction;
  currency: string;
  amount: Amount;
  bonusInfo: UserBonusInfo;
  currencyDisplaySetting?: CurrencyDisplaySetting;
}

interface GetCalculatedBonusProps {
  absoluteBonusValue?: number | null;
  percentBonusValue?: number | null;
  amountAsNumber: number;
  maxDepositAmount: number;
  maxBonusValue?: number | null;
}

const getCalculatedBonus = (
  props: GetCalculatedBonusProps,
): number => {
  const {
    absoluteBonusValue = 0,
    percentBonusValue = 0,
    amountAsNumber,
    // maxDepositAmount = 0,
    maxBonusValue = 0,
  } = props;

  if (absoluteBonusValue) {
    return absoluteBonusValue;
  }

  let calculatedBonusAmount = 0;

  if (percentBonusValue && maxBonusValue) {
    calculatedBonusAmount = amountAsNumber * (percentBonusValue / 100);

    if (calculatedBonusAmount > maxBonusValue) {
      return maxBonusValue;
    }

    // TODO: should be deleted if we don't need it
    // if (maxDepositAmount && amountAsNumber > maxDepositAmount) {
    //   return ((maxDepositAmount * percentBonusValue) / 100);
    // }
  }

  return calculatedBonusAmount;
};

const getBonusButtonText = (props: GetBonusTextProps): string => {
  const {
    t,
    amount,
    currency,
    bonusInfo,
    currencyDisplaySetting,
  } = props;
  const {
    minDepositAmount,
    absoluteBonusValue,
    maxBonusValue = 0,
    percentBonusValue = 0,
    maxDepositAmount = 0,
  } = bonusInfo;
  const amountAsNumber:number = Number(amount) || 0;
  let calculatedAmount = 0;

  if (amountAsNumber && minDepositAmount && amountAsNumber >= minDepositAmount) {
    calculatedAmount = getCalculatedBonus({
      absoluteBonusValue,
      percentBonusValue,
      amountAsNumber,
      maxDepositAmount,
      maxBonusValue,
    });
  }

  if (calculatedAmount) {
    const formattedAmount:string = Number(calculatedAmount.toFixed(2)).toString();

    return t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.BONUS`, {
      amount: getFormattedAmountWithCurrency(
        getPrettierFormatNumber(formattedAmount),
        currency,
        currencyDisplaySetting,
      ),
    });
  }

  return '';
};

type ConversionProps = {
  amount: Amount;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  currencyDisplaySetting?: CurrencyDisplaySetting;
};

export const getConversionText = ({
  amount,
  processingCurrency,
  processingCurrencyRate,
  currencyDisplaySetting,
}: ConversionProps) => {
  if (!processingCurrency || !processingCurrencyRate) {
    return `${getPrettierFormatNumber(amount)}`;
  }

  const amountAsNumber = typeof amount === 'number' ? amount : parseFloat(amount);
  const alpha3 = processingCurrency?.alpha3 || '';
  const convertedAmount = (amountAsNumber * processingCurrencyRate).toFixed(2);

  return getFormattedAmountWithCurrency(getPrettierFormatNumber(convertedAmount), alpha3, currencyDisplaySetting);
};

interface GetSubmitSubTextProps {
  t: TFunction;
  currency: string;
  amount: Amount;
  bonusInfo?: UserBonusInfo;
  isBonusIncluded?: boolean;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  currencyDisplaySetting?: CurrencyDisplaySetting;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
}

interface GetFormattedFeeSubTextProps {
  amount: Amount;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string | null;
}

const getFeeAmountSubtext = ({ amount, merchantUserFee, fee }: GetFormattedFeeSubTextProps) => {
  const amountAsNumber = Number(amount);
  const feeAsNumber = typeof fee === 'string' ? Number(fee.replace('%', '')) : 0;
  const percentageFeeAmountMerchant = merchantUserFee?.percentage
    ? (amountAsNumber * (merchantUserFee.percentage / 100))
    : 0;
  const percentageFeeAmount = feeAsNumber ? (amountAsNumber * (feeAsNumber / 100)) : 0;
  const fixedFeeAmount = merchantUserFee?.fix || 0;
  return (percentageFeeAmountMerchant || percentageFeeAmount) + fixedFeeAmount;
};

export const getSubmitSubText = (props: GetSubmitSubTextProps): string => {
  const {
    t,
    currency,
    amount,
    bonusInfo,
    isBonusIncluded,
    processingCurrency,
    processingCurrencyRate,
    currencyDisplaySetting,
    merchantUserFee,
    fee,
  } = props;
  const messages: string[] = [];

  if (processingCurrency && processingCurrencyRate) {
    messages.push(getConversionText({
      amount, processingCurrency, processingCurrencyRate, currencyDisplaySetting,
    }));
  }

  if ((merchantUserFee || fee) && getFeeAmountSubtext({
    amount, merchantUserFee, fee,
  })) {
    const tKey = `${NAMESPACES.PW_KEYS}:PH.DEPOSIT.LABEL.FEE`;
    messages.push(`${t(tKey)} ${getFormattedAmountWithCurrency(
      formatAmount(getFeeAmountSubtext({
        amount, merchantUserFee, fee,
      })),
      currency,
      currencyDisplaySetting,
    )}`);
  }

  if (bonusInfo && isBonusIncluded) {
    messages.push(getBonusButtonText({
      t,
      amount,
      currency,
      bonusInfo,
      currencyDisplaySetting,
    }));
  }

  return messages.length ? messages.join('; ') : '';
};

interface ValidPresettedAmount {
  presetAmount: number;
  amountBlocks?: number[];
  min?: number;
  max?: number;
}

const isNumberInRange = (value: number, min?: number, max?: number) => (
  value && (!min || value >= min) && (!max || value <= max));

export const getValidPresettedAmount = ({
  presetAmount,
  amountBlocks,
  min,
  max,
}: ValidPresettedAmount) => {
  const initialSelectedAmountIndex = getInitialSelectedAmountIndex(amountBlocks);
  const initialSelectedAmountBlock = amountBlocks ? amountBlocks[initialSelectedAmountIndex] : 0;

  if (isNumberInRange(presetAmount, min, max)) {
    return presetAmount;
  }
  if (isNumberInRange(initialSelectedAmountBlock, min, max)) {
    return initialSelectedAmountBlock;
  }

  return min || 1;
};

interface SetDepositInitDataToStoreProps {
  initData: UIPayment;
  dispatch: Dispatch;
  navigate: NavigateFunction;
  payGroupId?: number;
}

export const setDepositInitDataToStore = (props: SetDepositInitDataToStoreProps) => {
  const {
    initData,
    dispatch,
    navigate,
    payGroupId,
  } = props;

  if (!payGroupId) {
    return;
  }

  const method = initData.methods.find(({ id }) => id === payGroupId);

  if (!method) {
    return;
  }

  selectDepositMethodHandler({
    method,
    platform: initData?.widgetSettings?.platform,
    dispatch,
    navigate,
    flowType: FlowTypes.deposit,
  }, {} as MouseEvent);
};

export const sortFields = (fields: Parameter[]) => {
  const fieldsCopy = [...fields];
  fieldsCopy?.sort((a: Parameter, b: Parameter) => {
    if (a.type === PayHubEnumsCashierMethodParamType.FileUpload) return 1;
    if (b.type === PayHubEnumsCashierMethodParamType.FileUpload) return -1;
    if (a.extendedType
        === PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType.DisabledInputCopy) return 1;
    if (b.extendedType
        === PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType.DisabledInputCopy) return -1;
    return 0;
  });
  return fieldsCopy;
};

interface UploadErrorMessageProps {
  t: TFunction;
  errorMessage?: string;
}

export const getUploadErrorMessage = ({ errorMessage, t }: UploadErrorMessageProps) => {
  const key = errorMessage
    ? 'PH.DEPOSIT.UPLOAD_DOC.LIMIT_UPLOAD_ERROR'
    : 'PH.DEPOSIT.UPLOAD_DOC.UPLOAD_ERROR';

  return t(`${NAMESPACES.PW_KEYS}:${key}`);
};
