/* eslint-disable camelcase */
import { FC, lazy, Suspense, useEffect, useState } from 'react';
import classnames from 'classnames';
import { CLOSE_ICON, PAGE_STATUS } from 'utils/constants';
import {
  appConstants,
  CLICKSTREAM_STATUSES_MAP,
  getLayoutType,
  getMerchantRedirectUrl,
  inIframe,
  LayoutType,
  softLocalStorageGet,
  softLocalStorageSet,
  urlFormer,
  softSessionStorageSet, getMerchantUrl,
  parseQuery,
  stringToInt,
} from 'utils';
import IntegrationCommunication from 'services/integration-communication';
import { FlowTypes } from 'store/constants';
import { Timeout } from 'react-number-format/types/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  platformSelector,
  setIsStatusPageMode,
  setHeadersNavIcon,
  resetReturnUrls,
  resetMerchantRedirectUrl,
  returnUrlsSelector,
  merchantRedirectUrlSelector,
  notificationConnectionOpenOn, notificationConnectionOpenOff, isInPopUpSelector,
} from 'store/slices/global';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamEvent,
  getClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { NotificationService } from 'services/notificationService/NotificatioinService';
import { paySessionStateTargetName } from 'services/notificationService/constants';
import { paySessionStateHandlerCreator } from 'services/notificationService/handlers/paySessionStateHandler';
import { paySessionStateSelector } from 'store/slices/paySessionState';
import isUrl from 'is-url';
import { getProcessingTimeData, statusRedirectOnProcessing } from '../components/SubmitForm/utils';
import { TransactionProcessing } from './TransactionProcessing';
import {
  closePopUpStatusPageButtonHandler, getCalculatedTimeoutByStatus,
  hasCloseCashierMsg,
  redirectFrameOrOpenNewWindow,
  returnUrlFormer,
} from './utils';
import { useFrameChangeHeight, useGetSessionId, useNavigateWithSearch } from '../hooks';
import { FlatStringObject } from '../../commonTypes';
import ModulorLoader from '../components/ModulorLoader';

import './Status.scss';
import { NAMESPACES } from '../../services/constants';

const StatusRoute = lazy(() => import('./StatusRoute'));

const AB_STATUS_BTN_TEXT_MAP = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [PAGE_STATUS.success]: `${NAMESPACES.PW_KEYS}:PH.BUTTON.BACK_TO_GAME`,
  [PAGE_STATUS.error]: `${NAMESPACES.PW_KEYS}:PH.BUTTON.RETRY`,
  [PAGE_STATUS.processing]: `${NAMESPACES.PW_KEYS}:PH.BUTTON.BACK_TO_GAME`,
  [PAGE_STATUS.await]: `${NAMESPACES.PW_KEYS}:PH.BUTTON.BACK_TO_GAME`,
  [PAGE_STATUS['charity-success']]: '',
};

const TRANSACTION_STATUS_MAP: FlatStringObject = {
  processing: 'processing',
  success: 'success',
  fail: 'error',
  processing_error: 'error',
  order_cancelled: 'error',
  awaiting: 'await',
  await: 'await',
};

let timer : string | number | Timeout | undefined;

interface StatusPageProps {
  status: PAGE_STATUS;
  type: FlowTypes;
}

type StatusPageInfoText = {
  title: string;
  bodyText?: string;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const StatusController: FC<StatusPageProps> = (props) => {
  const {
    status,
    type,
  } = props;
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const cashierContext = useGetClickStreamCashierContext(type);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const paySessionState = useAppSelector(paySessionStateSelector);

  const {
    action,
    result_status,
    transaction_status = null,
    success_url,
    processing_url,
    fail_url,
    merchant_url: merchantUrl,
  } = paySessionState || {};

  const navigate = useNavigateWithSearch();
  const isAwait = status === PAGE_STATUS.await;
  const isSuccess = status === PAGE_STATUS.success || status === PAGE_STATUS['charity-success'];
  const isMobile = getLayoutType() === LayoutType.mobile;
  const isOpenedInPopup = useAppSelector(isInPopUpSelector);
  const shouldCloseCashier = hasCloseCashierMsg(isOpenedInPopup, status);
  const btnText = AB_STATUS_BTN_TEXT_MAP[status];
  const isDeposit = type === FlowTypes.deposit;
  const processingTimeData = getProcessingTimeData();

  const params = parseQuery(window.location.search);
  const rtParamFromURL = typeof params.rt === 'string' && stringToInt(params.rt);
  const calculatedTimeout = getCalculatedTimeoutByStatus(status, paySessionState, rtParamFromURL);

  const storedTransactionStatus = softLocalStorageGet(appConstants.transaction_status);
  const [isTransactionSet, setTransactionStatus] = useState<string | undefined | null>(storedTransactionStatus);
  const isTransactionStatusProcessing = ['processing'].includes(transaction_status || '')
    || (action === 'await' && result_status === 'processing');
  const platform = useAppSelector(platformSelector);
  const merchantRedirectUrlFromState = useAppSelector(merchantRedirectUrlSelector);
  const {
    merchantUrl: merchantUrlFromState,
    merchantSuccessUrl,
    merchantFailUrl,
    merchantProcessingUrl,
    merchantRedirectType,
  } = useAppSelector(returnUrlsSelector);
  const merchant_redirect_url = getMerchantRedirectUrl({
    merchantSuccessUrl,
    merchantFailUrl,
    merchantProcessingUrl,
    merchantRedirectUrlFromState,
  });
  const merchant_url = getMerchantUrl(merchantUrl) || merchantUrlFromState;
  const decodedUrl = merchant_redirect_url ? decodeURI(merchant_redirect_url) : false;

  const getUrlToRedirect = returnUrlFormer({
    success_url,
    processing_url,
    fail_url,
    flowType: type,
    decodedUrl,
    merchant_url,
  });

  const sessionId: string = useGetSessionId();
  const notificationService = isDeposit ? NotificationService.getInstance(sessionId, dispatch) : null;
  const notificationServiceCallback = paySessionStateHandlerCreator({
    dispatch,
    timer,
    platform,
    flowType: type,
    isOpenedInPopup,
  });

  const stopTimerAndRedirect = async () => {
    const urlToRedirect = getUrlToRedirect(status);
    const event = getClickStreamEvent.cashier_result_page_intogame_redirect(
      CLICKSTREAM_STATUSES_MAP[status],
      urlToRedirect,
    );
    clickstream.push(event, [cashierContext,
      payGroupContext]);
    if (isDeposit) {
      await notificationService?.stop(paySessionStateTargetName);
    }

    dispatch(notificationConnectionOpenOff());
    statusRedirectOnProcessing({
      platform,
      isSuccess,
      urlToRedirect,
      isOpenedInPopup,
    });
    clearTimeout(timer);
  };

  const sendCloseClickstreamEvent = () => {
    const {
      decline_page_settings = null,
    } = paySessionState || {};
    // eslint-disable-next-line max-len
    const declineReason = !decline_page_settings ? null : decline_page_settings.user_decline_message_translation_key || null;
    const event = getClickStreamEvent.cashier_result_page_close_icon_click(
      CLICKSTREAM_STATUSES_MAP[status],
      declineReason,
    );
    clickstream.push(event, [cashierContext,
      payGroupContext]);
  };

  useFrameChangeHeight(true);

  useEffect(() => {
    async function onStart() {
      try {
        dispatch(setIsStatusPageMode(true));
        const { href = '' } = window?.parent?.location || {};
        const hasParentCashier = window?.parent !== window?.self && (
          href.includes('deposit')
          || href.includes('withdrawal')
          || href.includes('cashier')
          || href.includes('demeter')
        );
        const { search } = window.self.location;

        if (hasParentCashier) {
          window.parent.postMessage({
            id: 'redirectFromInnerFrame',
            status,
            type,
            search,
          }, '*');
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('No parent here.');
      }

      if (isDeposit) {
        await notificationService?.start();
      }

      dispatch(notificationConnectionOpenOn());

      if (isDeposit
        && !isAwait
      ) {
        notificationService?.subscribe({
          name: paySessionStateTargetName,
          callback: notificationServiceCallback,
        });
      }
    }

    dispatch(setHeadersNavIcon(''));
    softSessionStorageSet(CLOSE_ICON, 'false');

    if (isDeposit) {
      onStart();
    }

    return () => {
      async function onStop() {
        await notificationService?.stop(paySessionStateTargetName);
      }

      if (isDeposit) {
        onStop();
      }

      dispatch(notificationConnectionOpenOff());
      dispatch(setIsStatusPageMode(false));
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('СlickstreamClose', sendCloseClickstreamEvent);
    if (!paySessionState.success_url) {
      const {
        decline_page_settings = null,
      } = paySessionState || {};
      // eslint-disable-next-line max-len
      const declineReason = !decline_page_settings ? null : decline_page_settings.user_decline_message_translation_key || null;
      const event = getClickStreamEvent.cashier_result_page_view(
        CLICKSTREAM_STATUSES_MAP[status],
        declineReason,
      );
      clickstream.push(event, [cashierContext,
        payGroupContext]);
    }

    return () => {
      document.removeEventListener('СlickstreamClose', sendCloseClickstreamEvent);
    };
  }, [status]);

  useEffect(() => {
    if (!isTransactionSet && !isAwait && isDeposit) {
      timer = setTimeout(
        stopTimerAndRedirect,
        calculatedTimeout,
      );
    }

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (isTransactionSet && !isAwait && isDeposit) {
      if (status !== PAGE_STATUS.error) {
        timer = setTimeout(
          stopTimerAndRedirect,
          calculatedTimeout,
        );
      }
    }
  }, [
    isTransactionSet,
    isDeposit,
    status,
    isAwait,
    type,
  ]);

  useEffect(() => {
    const workStatus = transaction_status || result_status;

    if (workStatus && isDeposit) {
      const mapperStatus = TRANSACTION_STATUS_MAP[`${workStatus}`];
      const isResultStatus = mapperStatus && [
        'success',
        'error',
      ].includes(mapperStatus);

      if (isResultStatus) {
        softLocalStorageSet(appConstants.transaction_status, 'set');
        setTransactionStatus('set');
        clearTimeout(timer);

        timer = setTimeout(
          async () => {
            await stopTimerAndRedirect();
          },
          calculatedTimeout,
        );

        navigate(`/${type}/${type}-${mapperStatus}`);
      }
    }
  }, [
    type,
    transaction_status,
    result_status,
    isDeposit,
    status,
  ]);

  if (
    (!(transaction_status || result_status) || isTransactionStatusProcessing)
    && (!isTransactionSet || isTransactionStatusProcessing)
    && isDeposit
    && !isAwait
    && ![PAGE_STATUS.success,
      PAGE_STATUS.error].includes(status)
  ) {
    return (
      <TransactionProcessing
        stopTimer={!!isTransactionSet}
        urlToRedirect={getUrlToRedirect(status)}
      />
    );
  }

  const handleCasinoCallback = () => {
    if (shouldCloseCashier) {
      IntegrationCommunication.sendMessage({
        id: 'closeCashier',
      });
    } else {
      const event = getClickStreamEvent.cashier_result_page_intogame_button_click('/casino/slots');
      clickstream.push(event, [cashierContext]);
      IntegrationCommunication.sendMessage({
        id: 'redirectTo',
        value: '/casino/slots',
      });
    }
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleStatusCallback = (isBackToGameButtonClicked: boolean | undefined) => {
    const isProcessing = status === PAGE_STATUS.processing;

    if (merchant_redirect_url) {
      dispatch(resetReturnUrls());
      dispatch(resetMerchantRedirectUrl());
    }

    if (isOpenedInPopup) {
      closePopUpStatusPageButtonHandler({
        success_url,
        processing_url,
        fail_url,
        status,
      });
      return;
    }

    if (inIframe()) {
      if (isSuccess || isProcessing) {
        const event = getClickStreamEvent.cashier_result_page_intogame_button_click(decodedUrl || '/');
        clickstream.push(event, [cashierContext]);
        if (typeof merchantRedirectType === 'string') {
          const urlToApply = getUrlToRedirect(status);

          if (!isUrl(urlToApply)) {
            // eslint-disable-next-line no-console
            console.error('invalid url: ', urlToApply);
            return;
          }

          const merchantRedirectTypeToApply = merchantRedirectType.toLowerCase() === 'redirect'
          && urlToApply === merchant_url
            ? 'top'
            : merchantRedirectType;

          switch (merchantRedirectTypeToApply.toLowerCase()) {
            case 'redirect': {
              window.location.href = urlToApply;
              return;
            }

            case 'blank': {
              window.open(urlToApply, '_blank');
              return;
            }

            case 'parent': {
              window.parent.location.href = urlToApply;
              return;
            }

            default: {
              IntegrationCommunication.sendMessage({
                id: 'redirectTo',
                // eslint-disable-next-line sonarjs/no-gratuitous-expressions
                value: urlToApply,
              });
            }
          }
        } else {
          IntegrationCommunication.sendMessage({
            id: 'redirectTo',
            value: getUrlToRedirect(status),
          });
        }

        return;
      }
      IntegrationCommunication.sendMessage({
        id: 'reInitCashier',
      });
    } else {
      const replaceURL = (!!decodedUrl && getUrlToRedirect(status)) || urlFormer(
        merchant_url,
        status,
        isBackToGameButtonClicked,
      );
      if (!isUrl(replaceURL)) {
        // eslint-disable-next-line no-console
        console.error('invalid url: ', replaceURL);
        return;
      }
      const event = getClickStreamEvent.cashier_result_page_intogame_button_click(replaceURL);
      clickstream.push(event, [cashierContext]);
      redirectFrameOrOpenNewWindow(replaceURL, merchantRedirectType);
    }
  };

  const getInfoText = (): StatusPageInfoText => {
    if (type === FlowTypes.withdrawal && status === PAGE_STATUS.success) {
      const infoText = {
        title: `${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.STATUS_PAGE.PROCESSING.TITLE`,
      };

      if (processingTimeData?.processingTime) {
        return {
          ...infoText,
          bodyText: `${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.STATUS_PAGE.PROCESSING.BODY`,
        };
      }
      return infoText;
    }
    const mappedStatusKey = (status === PAGE_STATUS.error ? 'fail' : status).toUpperCase();
    return {
      title: `${NAMESPACES.PW_KEYS}:PH.${type.toUpperCase()}.STATUS_PAGE.${mappedStatusKey}.TITLE`,
      bodyText: `${NAMESPACES.PW_KEYS}:PH.${type.toUpperCase()}.STATUS_PAGE.${mappedStatusKey}.BODY`,
    };
  };

  return (
    <div
      className={classnames('payment-container', {
        mobile: isMobile,
      })}
    >
      <div
        className="status-wrapper"
      >
        <Suspense fallback={<ModulorLoader />}>
          <StatusRoute
            status={status}
            isMobile={isMobile}
            title={getInfoText().title}
            bodyText={getInfoText().bodyText || ''}
            btnText={btnText}
            callback={handleStatusCallback}
            casinoCallback={handleCasinoCallback}
            type={type}
            processingTimeData={processingTimeData}
          />
        </Suspense>
      </div>
    </div>
  );
};

export default StatusController;
