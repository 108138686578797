import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useAppSelector } from 'store/hooks';
import { betShopOptionsSelector, currentBetShopSelector } from 'store/slices/withdrawal';
import { BetShopsList } from './BetShopsList';

import './BetShops.scss';
import { SelectedBetShop } from './SelectedBetShop';

interface BetShopsFieldProps {
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  control: Control;
  isRequired?: boolean;
}

export const BetShopsField = ({
  setValue,
  name,
  control,
  isRequired,
}: BetShopsFieldProps) => {
  const [displayList, setListOpened] = useState(false);

  const currentBetShop = useAppSelector(currentBetShopSelector);
  const betShopOptions = useAppSelector(betShopOptionsSelector);

  const onFieldClick = () => {
    setListOpened(true);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
      }}
      render={() => (
        <>
          <SelectedBetShop
            onClick={onFieldClick}
            currentBetShop={currentBetShop}
          />
          <BetShopsList
            name={name}
            isOpen={displayList}
            setOpen={setListOpened}
            options={betShopOptions}
            setValue={setValue}
          />
        </>
      )}
    />
  );
};
