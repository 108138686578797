import {
  brand0,
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  brand10,
  brand11,
  brand12,
  brand13,
  brand14,
  brand15,
  brand16,
  brand17,
  brand18,
  brand19,
  brand20,
  brand21,
  brand22,
  brand23,
  brand24,
  brand25,
  brand26,
  brand27,
  brand28,
  brand29,
  brand30,
  brand31,
  brand32,
  brand33,
  brand34,
  brand36,
  brand37,
  brand38,
  brand39,
  brand40,
  brand41,
  brand42,
  brand44,
  brand45,
  brand46,
  brand47,
  brand48,
  brand49,
  brand50,
  brand51,
  brand52,
  brand53,
  brand54,
  brand55,
  brand56,
  brand57,
  brand58,
  brand59,
  brand60,
  brand61,
  brand62,
  brand63,
  brand64,
  brand65,
  brand66,
  brand67,
  brand68,
  brand69,
  brand70,
  brand71,
  brand72,
  brand73,
  brand74,
  brand75,
  brand76,
  brand77,
  brand78,
  brand79,
  brand80,
} from '@betbook/modulor-brands';
import { FlatStringObject } from '../commonTypes';

export const THEMES = {
  brand0,
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand8,
  brand9,
  brand10,
  brand11,
  brand12,
  brand13,
  brand14,
  brand15,
  brand16,
  brand17,
  brand18,
  brand19,
  brand20,
  brand21,
  brand22,
  brand23,
  brand24,
  brand25,
  brand26,
  brand27,
  brand28,
  brand29,
  brand30,
  brand31,
  brand32,
  brand33,
  brand34,
  brand36,
  brand37,
  brand38,
  brand39,
  brand40,
  brand41,
  brand42,
  brand44,
  brand45,
  brand46,
  brand47,
  brand48,
  brand49,
  brand50,
  brand51,
  brand52,
  brand53,
  brand54,
  brand55,
  brand56,
  brand57,
  brand58,
  brand59,
  brand60,
  brand61,
  brand62,
  brand63,
  brand64,
  brand65,
  brand66,
  brand67,
  brand68,
  brand69,
  brand70,
  brand71,
  brand72,
  brand73,
  brand74,
  brand75,
  brand76,
  brand77,
  brand78,
  brand79,
  brand80,
};

export type THEME_TYPE =
'brand0'
| 'brand1'
| 'brand2'
| 'brand3'
| 'brand4'
| 'brand5'
| 'brand6'
| 'brand7'
| 'brand8'
| 'brand9'
| 'brand10'
| 'brand11'
| 'brand12'
| 'brand13'
| 'brand14'
| 'brand15'
| 'brand16'
| 'brand17'
| 'brand18'
| 'brand19'
| 'brand20'
| 'brand21'
| 'brand22'
| 'brand23'
| 'brand24'
| 'brand25'
| 'brand26'
| 'brand27'
| 'brand28'
| 'brand29'
| 'brand30'
| 'brand31'
| 'brand32'
| 'brand33'
| 'brand34'
| 'brand36'
| 'brand37'
| 'brand38'
| 'brand39'
| 'brand40'
| 'brand41'
| 'brand42'
| 'brand44'
| 'brand45'
| 'brand46'
| 'brand47'
| 'brand48'
| 'brand49'
| 'brand50'
| 'brand51'
| 'brand52'
| 'brand53'
| 'brand54'
| 'brand55'
| 'brand56'
| 'brand57'
| 'brand58'
| 'brand59'
| 'brand60'
| 'brand61'
| 'brand62'
| 'brand63'
| 'brand64'
| 'brand65'
| 'brand66'
| 'brand67'
| 'brand68'
| 'brand69'
| 'brand70'
| 'brand71'
| 'brand72'
| 'brand73'
| 'brand74'
| 'brand75'
| 'brand76'
| 'brand77'
| 'brand78'
| 'brand79'
| 'brand80';
export const THEME_TYPE_LIST = [
  'brand0',
  'brand1',
  'brand2',
  'brand3',
  'brand4',
  'brand5',
  'brand6',
  'brand7',
  'brand8',
  'brand9',
  'brand10',
  'brand11',
  'brand12',
  'brand13',
  'brand14',
  'brand15',
  'brand16',
  'brand17',
  'brand18',
  'brand19',
  'brand20',
  'brand21',
  'brand22',
  'brand23',
  'brand24',
  'brand25',
  'brand26',
  'brand27',
  'brand28',
  'brand29',
  'brand30',
  'brand31',
  'brand32',
  'brand33',
  'brand34',
  'brand36',
  'brand37',
  'brand38',
  'brand39',
  'brand40',
  'brand41',
  'brand42',
  'brand44',
  'brand45',
  'brand46',
  'brand47',
  'brand48',
  'brand49',
  'brand50',
  'brand51',
  'brand52',
  'brand53',
  'brand54',
  'brand55',
  'brand56',
  'brand57',
  'brand58',
  'brand59',
  'brand60',
  'brand61',
  'brand62',
  'brand63',
  'brand64',
  'brand65',
  'brand66',
  'brand67',
  'brand68',
  'brand69',
  'brand70',
  'brand71',
  'brand72',
  'brand73',
  'brand74',
  'brand75',
  'brand76',
  'brand77',
  'brand78',
  'brand79',
  'brand80',
];

export const DARK_THEME_MODE_TYPE = 'dark';
export const LIGHT_THEME_MODE_TYPE = 'light';

export const THEME_BRANDS_MODE_MAPPING: FlatStringObject = {
  brand0: LIGHT_THEME_MODE_TYPE,
  brand1: LIGHT_THEME_MODE_TYPE,
  brand2: LIGHT_THEME_MODE_TYPE,
  brand3: LIGHT_THEME_MODE_TYPE,
  brand4: LIGHT_THEME_MODE_TYPE,
  brand5: LIGHT_THEME_MODE_TYPE,
  brand6: LIGHT_THEME_MODE_TYPE,
  brand7: LIGHT_THEME_MODE_TYPE,
  brand8: DARK_THEME_MODE_TYPE,
  brand9: LIGHT_THEME_MODE_TYPE,
  brand10: LIGHT_THEME_MODE_TYPE,
  brand11: DARK_THEME_MODE_TYPE,
  brand12: LIGHT_THEME_MODE_TYPE,
  brand13: DARK_THEME_MODE_TYPE,
  brand14: LIGHT_THEME_MODE_TYPE,
  brand15: DARK_THEME_MODE_TYPE,
  brand16: DARK_THEME_MODE_TYPE,
  brand17: DARK_THEME_MODE_TYPE,
  brand18: LIGHT_THEME_MODE_TYPE,
  brand19: LIGHT_THEME_MODE_TYPE,
  brand20: DARK_THEME_MODE_TYPE,
  brand21: DARK_THEME_MODE_TYPE,
  brand22: LIGHT_THEME_MODE_TYPE,
  brand23: LIGHT_THEME_MODE_TYPE,
  brand24: DARK_THEME_MODE_TYPE,
  brand25: LIGHT_THEME_MODE_TYPE,
  brand26: DARK_THEME_MODE_TYPE,
  brand27: DARK_THEME_MODE_TYPE,
  brand28: DARK_THEME_MODE_TYPE,
  brand29: LIGHT_THEME_MODE_TYPE,
  brand30: DARK_THEME_MODE_TYPE,
  brand31: DARK_THEME_MODE_TYPE,
  brand32: LIGHT_THEME_MODE_TYPE,
  brand33: DARK_THEME_MODE_TYPE,
  brand34: DARK_THEME_MODE_TYPE,
  brand36: DARK_THEME_MODE_TYPE,
  brand37: DARK_THEME_MODE_TYPE,
  brand38: DARK_THEME_MODE_TYPE,
  brand39: DARK_THEME_MODE_TYPE,
  brand40: DARK_THEME_MODE_TYPE,
  brand41: LIGHT_THEME_MODE_TYPE,
  brand42: DARK_THEME_MODE_TYPE,
  brand44: DARK_THEME_MODE_TYPE,
  brand45: DARK_THEME_MODE_TYPE,
  brand46: LIGHT_THEME_MODE_TYPE,
  brand47: DARK_THEME_MODE_TYPE,
  brand48: DARK_THEME_MODE_TYPE,
  brand49: DARK_THEME_MODE_TYPE,
  brand50: LIGHT_THEME_MODE_TYPE,
  brand51: DARK_THEME_MODE_TYPE,
  brand52: DARK_THEME_MODE_TYPE,
  brand53: DARK_THEME_MODE_TYPE,
  brand54: LIGHT_THEME_MODE_TYPE,
  brand55: DARK_THEME_MODE_TYPE,
  brand56: DARK_THEME_MODE_TYPE,
  brand57: DARK_THEME_MODE_TYPE,
  brand58: DARK_THEME_MODE_TYPE,
  brand59: DARK_THEME_MODE_TYPE,
  brand60: DARK_THEME_MODE_TYPE,
  brand61: LIGHT_THEME_MODE_TYPE,
  brand62: LIGHT_THEME_MODE_TYPE,
  brand63: LIGHT_THEME_MODE_TYPE,
  brand64: LIGHT_THEME_MODE_TYPE,
  brand65: LIGHT_THEME_MODE_TYPE,
  brand66: DARK_THEME_MODE_TYPE,
  brand67: LIGHT_THEME_MODE_TYPE,
  brand68: LIGHT_THEME_MODE_TYPE,
  brand69: LIGHT_THEME_MODE_TYPE,
  brand70: DARK_THEME_MODE_TYPE,
  brand71: LIGHT_THEME_MODE_TYPE,
  brand72: LIGHT_THEME_MODE_TYPE,
  brand73: LIGHT_THEME_MODE_TYPE,
  brand74: LIGHT_THEME_MODE_TYPE,
  brand75: LIGHT_THEME_MODE_TYPE,
  brand76: LIGHT_THEME_MODE_TYPE,
  brand77: LIGHT_THEME_MODE_TYPE,
  brand78: LIGHT_THEME_MODE_TYPE,
  brand79: LIGHT_THEME_MODE_TYPE,
  brand80: LIGHT_THEME_MODE_TYPE,
};
