import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { PhoneField } from '@modulor/react';
import { FieldType, findCountryDetailsByCountryISO, findCountryDetailsByPhoneNumber } from 'utils';
import CustomModal from 'Modulor/CustomModal';
import { NAMESPACES } from 'services/constants';
import { CountryCode } from 'assets/countryDialCode';
import { useAppSelector } from 'store/hooks';
import { countryCodeSelector } from 'store/slices/global';
import { getErrorKey } from '../../fieldErrors';
import { CountrySelector } from './CountrySelector';
import { isValidPhoneFormat } from './utils';

interface CustomPhoneFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  isRequired?: boolean;
  defaultValue?: string | null;
  isDisabled?: boolean;
  validationMsgKey?: string;
}

export const CustomPhoneField: FC<CustomPhoneFieldProps> = (props) => {
  const {
    name,
    label,
    control,
    isRequired,
    isDisabled = false,
    defaultValue,
    validationMsgKey,
  } = props;
  const { t } = useTranslation();

  const [countryCodeSelectorVisibility, setCountryCodeSelectorVisibility] = useState<boolean>(false);
  const currentCountryISO = useAppSelector(countryCodeSelector);

  const [phone, setPhone] = useState('+');
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const isValidDefaultPhoneNumber = defaultValue && isValidPhoneFormat(defaultValue);
    if (isValidDefaultPhoneNumber) {
      const countryDetails = findCountryDetailsByPhoneNumber(defaultValue);
      setPhone(defaultValue);
      setCountryCode(countryDetails?.iso || '');
    }
    if (!isValidDefaultPhoneNumber && currentCountryISO) {
      const countryDetails = findCountryDetailsByCountryISO(currentCountryISO);
      setPhone(countryDetails?.phonecode ? `+${countryDetails.phonecode}` : '');
      setCountryCode(countryDetails?.iso || '');
    }
  }, [
    defaultValue,
    currentCountryISO,
  ]);

  const handleCountryCodeChange = (country: CountryCode) => {
    setPhone(`+${country.phonecode}`);
    setCountryCode(country.iso);
  };

  const handleOnPhoneChange = (value: string, field: FieldValues) => {
    const newValue = value.startsWith('+') ? value : `+${value}`;
    setPhone(newValue);
    field.onChange(newValue);
    const countryDetails = findCountryDetailsByPhoneNumber(newValue);
    setCountryCode(countryDetails?.iso || '');
  };

  const renderModalContent = () => (
    <CountrySelector
      setCountryCode={handleCountryCodeChange}
      countryCode={countryCode}
      toggleModal={setCountryCodeSelectorVisibility}
    />
  );

  const fieldLabel = label || name ? t(`${NAMESPACES.INFO}:${label || name}`) : '';

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: isRequired,
      }}
      render={({ field, fieldState }: FieldValues) => {
        const errorMessageKey = getErrorKey(FieldType.input, fieldState, validationMsgKey);
        const errorMessage = t(errorMessageKey);

        return (
          <>
            <PhoneField
              {...field}
              name={name}
              value={phone}
              error={errorMessage}
              label={fieldLabel}
              disabled={isDisabled}
              readOnly={isDisabled}
              onFlagClick={() => setCountryCodeSelectorVisibility(true)}
              countryCode={countryCode}
              onChange={(value: string) => handleOnPhoneChange(value, field)}
            />
            <CustomModal
              isPopupView
              onShadowClick={() => setCountryCodeSelectorVisibility(false)}
              title={t(`${NAMESPACES.INFO}:CASHIER.PH2.COUNTRY`)}
              openModal={countryCodeSelectorVisibility}
            >
              {[renderModalContent()]}
            </CustomModal>
          </>
        );
      }}
    />
  );
};
