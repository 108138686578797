/* eslint-disable @typescript-eslint/no-unsafe-assignment, camelcase */
import { FC, useEffect, useRef, useState } from 'react';
import clx from 'classnames';

import { FlatStringObject } from 'commonTypes';
import { getLayoutType, inIframe, LayoutType } from 'utils';
import IntegrationCommunication from 'services/integration-communication';
import { FlowTypes, REDIRECT_FLOW_TYPES, SUBMIT_FORM_ACTIONS, SUBMIT_REDIRECT_ERROR_TIME } from 'store/constants';
import { PayHubDomainSessionsEnumsPlatformType } from 'store/models';
import { APP_NAME, pmAction } from 'utils/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  isInPopUpSelector,
  paymentFlowSelector,
  platformSelector,
  setIsCustomIframeMode,
} from 'store/slices/global';
import { setCarouselViewOff } from 'store/slices/carousel';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamEvent,
  getClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { useFrameChangeHeight, useGetSessionId, useNavigateWithSearch } from 'Modulor/hooks';
import { NotificationService } from 'services/notificationService/NotificatioinService';
import { paySessionStateTargetName, submitRedirectFailureTargetName } from 'services/notificationService/constants';
import { paySessionStateSelector } from 'store/slices/paySessionState';
// eslint-disable-next-line max-len
import { paySessionStateSubmitFormHandlerCreator } from 'services/notificationService/handlers/paySessionStateSubmitFormHandler';
import { resetSubmitRedirectError, submitRedirectErrorSelector } from 'store/slices/submitForm';
import { methodShortNameSelector } from 'store/slices/deposit';
import { Timeout } from 'react-number-format/types/types';
// eslint-disable-next-line max-len
import { submitErrorRedirectFormHandlerCreator } from 'services/notificationService/handlers/submitRedirectFailerHandler';
import {
  closeCashierAfterOpenInNewTab,
  formatParamsForForm,
  getFormTarget,
  getUrlToClose, isSafari,
} from './utils';
import './SubmitForm.scss';
import { FormContent } from './FormContent';
import { SubmitFormProps } from './types';

const TRANSACTION_STATUS_MAP: FlatStringObject = {
  processing: 'processing',
  success: 'success',
  fail: 'error',
  processing_error: 'error',
  order_cancelled: 'error',
  awaiting: 'await',
  await: 'await',
};

let errorTimer: Timeout;

export const SubmitForm: FC<SubmitFormProps> = (props) => {
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const flowType = useAppSelector(paymentFlowSelector);
  const isRedirectFailed = useAppSelector(submitRedirectErrorSelector);
  const shortMethodeName = useAppSelector(methodShortNameSelector);
  const navigate = useNavigateWithSearch();
  const cashierContext = useGetClickStreamCashierContext(flowType);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const formRef = useRef<HTMLFormElement>(null);
  const [isFormHandled, setIsFormHandled] = useState(false);
  const [isRedirectErrorCheck, setIsRedirectErrorCheck] = useState(false);
  const {
    redirectForm,
    paymentType,
    isAwait,
    isLLA = false,
  } = props;
  const {
    action,
    request_method: requestMethod,
    redirect_type,
  } = redirectForm;

  const isRedirectTypeParent = redirect_type && redirect_type === 'parent';
  const isRedirectTypeBlank = redirect_type && redirect_type === 'blank';
  const layoutType = getLayoutType();
  const isMobileLayout = layoutType === LayoutType.mobile;
  const platform = useAppSelector(platformSelector);
  const isOpenedInPopup = useAppSelector(isInPopUpSelector);
  const isNativeDevice = [
    PayHubDomainSessionsEnumsPlatformType.NativeIos,
    PayHubDomainSessionsEnumsPlatformType.NativeAndroid,
  ].includes(platform);

  const paySessionState = useAppSelector(paySessionStateSelector);
  const {
    transaction_status = null,
    result_status = null,
  } = paySessionState || {};

  const { requestUrl, requestFields } = formatParamsForForm(redirectForm);
  const isActionExpected = [
    SUBMIT_FORM_ACTIONS.redirect as string,
    SUBMIT_FORM_ACTIONS.blank as string,
  ].includes(action as string);
  const requestSessionTime = 30000;
  const isDeposit = window.location.pathname.includes(FlowTypes.deposit);
  const formTarget = getFormTarget({
    action,
    isActionExpected,
    redirectType: redirect_type,
    isLLA,
    isRedirectFailed: isRedirectErrorCheck && isRedirectTypeBlank ? isRedirectFailed : false,
  });

  const isInWeb = inIframe();
  const isInsideCustomIframe = isInWeb && formTarget === '_self' && !isRedirectTypeParent;
  const sessionId: string = useGetSessionId();
  const notificationService = !isInsideCustomIframe && !isLLA
    ? NotificationService.getInstance(sessionId, dispatch)
    : null;
  const notificationServiceCallback = paySessionStateSubmitFormHandlerCreator({
    dispatch,
  });
  const submitErrorRedirectCallback = submitErrorRedirectFormHandlerCreator({
    dispatch,
  });
  const submitFormContent = document.querySelector('#submit-content');

  useFrameChangeHeight(isFormHandled, {
    skip: isInsideCustomIframe,
  });

  useEffect(() => {
    if (isInsideCustomIframe) {
      dispatch(setIsCustomIframeMode(true));
    }
  }, [isInsideCustomIframe]);

  useEffect(() => {
    if (isRedirectErrorCheck && isRedirectFailed && formRef.current && isRedirectTypeBlank) {
      formRef.current.target = REDIRECT_FLOW_TYPES.top;

      setTimeout(() => {
        try {
          formRef?.current?.submit();
        } catch {
          formRef?.current?.dispatchEvent(new Event('submit'));
        }
      }, 0);
    }
  }, [
    isRedirectErrorCheck,
    isRedirectFailed,
  ]);

  useEffect(() => {
    async function start() {
      await notificationService?.start();
      notificationService?.subscribe({
        name: paySessionStateTargetName,
        callback: notificationServiceCallback,
      });
    }
    async function onStop() {
      await notificationService?.stop(paySessionStateTargetName);
    }

    dispatch(setCarouselViewOff());
    start();

    const timer = setTimeout(() => {
      onStop();
    }, requestSessionTime);

    return () => {
      dispatch(resetSubmitRedirectError());
      clearTimeout(timer);
      clearTimeout(errorTimer);
      onStop();
    };
  }, []);

  const handleSafariFlow = () => {
    // TODO: check closeCashierAfterOpenInNewTab(redirect_type);
    if (isOpenedInPopup) {
      IntegrationCommunication.sendMessage({
        id: 'closeCashier',
      });
    } else {
      IntegrationCommunication.sendMessage({
        id: 'redirectTo',
        value: '/',
      });
    }
  };

  useEffect(() => {
    // Sorry
    let isOk = true;
    async function onStop() {
      await notificationService?.stop(submitRedirectFailureTargetName);
    }
    // eslint-disable-next-line consistent-return
    async function start() {
      try {
        await notificationService?.start();
        notificationService?.subscribe({
          name: submitRedirectFailureTargetName,
          callback: submitErrorRedirectCallback,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Some error occurred');
        isOk = false;
        return () => {
          onStop();
        };
      }
    }

    if (isFormHandled) {
      setTimeout(start, 300);

      errorTimer = setTimeout(() => {
        if (isOk) {
          setIsRedirectErrorCheck(true);
        }
      }, SUBMIT_REDIRECT_ERROR_TIME);
    }

    return () => {
      onStop();
    };
  }, [isFormHandled]);

  useEffect(() => {
    if (isRedirectErrorCheck) {
      closeCashierAfterOpenInNewTab(isOpenedInPopup, redirect_type);
    }
  }, [isRedirectErrorCheck]);

  useEffect(() => {
    if (isSafari() && isFormHandled && submitFormContent && redirect_type !== 'blank') {
      handleSafariFlow();
    }
  }, [isFormHandled,
    submitFormContent,
    isSafari,
    redirect_type]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isFormHandled) {
        return;
      }

      if (!formRef.current) {
        return;
      }

      const clickstreamEvent = getClickStreamEvent.cashier_method_redirect_page_open;
      clickstream.push(clickstreamEvent, [cashierContext,
        payGroupContext]);

      try {
        formRef.current.submit();
      } catch {
        formRef.current.dispatchEvent(new Event('submit'));
      }
      setIsFormHandled(true);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    isFormHandled,
    paymentType,
    formTarget,
  ]);

  useEffect(() => {
    const workStatus = transaction_status || result_status;
    const providerIframe = document.querySelector('#iframe-host');

    if (workStatus && isDeposit && !providerIframe) {
      const mappedStatus = TRANSACTION_STATUS_MAP[`${workStatus}`];

      if (mappedStatus) {
        navigate(`/${paymentType}/${paymentType}-${mappedStatus}`);
      }
    }
  }, [
    transaction_status,
    result_status,
    paymentType,
  ]);

  if (!isActionExpected) {
    return null;
  }

  const handleSubmitForm = () => {
    const CHANGE_BUTTON_TIMEOUT = 3000;

    if (!isFormHandled) {
      if (isAwait) {
        setIsFormHandled(true);
      } else {
        const timer = setTimeout(() => {
          setIsFormHandled(true);
          clearTimeout(timer);
        }, CHANGE_BUTTON_TIMEOUT);
      }
    }
  };

  const handleGoBackClick = () => {
    if (isOpenedInPopup) {
      IntegrationCommunication.sendMessage({
        id: 'closeCashier',
      });
      return;
    }

    if (isInWeb) {
      IntegrationCommunication.sendMessage({
        id: 'redirectTo',
        value: '/',
      });
      IntegrationCommunication.sendMessage({
        id: 'successClick',
        target: APP_NAME,
        value: pmAction.deposit,
      });
    } else {
      if (isNativeDevice) {
        const closeUrl = getUrlToClose();
        window.location.assign(closeUrl);
        return;
      }

      window.location.href = window.location.origin;
    }
  };

  const handleRedirectClick = () => {
    const clickstreamEvent = getClickStreamEvent.cashier_method_redirect_link_click;
    clickstream.push(clickstreamEvent, [cashierContext,
      payGroupContext]);
  };

  return (
    <div
      id="iframe-host"
      className={clx({
        mobile: isMobileLayout,
      })}
    >
      <FormContent
        isInsideCustomIframe={isInsideCustomIframe}
        isDeposit={isDeposit}
        isFormHandled={isFormHandled}
        isLLA={isLLA}
        shortMethodeName={shortMethodeName}
        handleSubmitForm={handleSubmitForm}
        handleRedirectClick={handleRedirectClick}
        handleGoBackClick={handleGoBackClick}
        requestMethod={requestMethod}
        requestUrl={requestUrl}
        formTarget={formTarget}
        requestFields={requestFields}
        flowType={flowType}
        ref={formRef}
      />
    </div>

  );
};
