/* eslint-disable @typescript-eslint/no-empty-function */
import { FormEvent, useState } from 'react';
import { ListCell, ListView, Radio, RadioGroup, Typography, TextField, Icon } from '@modulor/react';
import { Option } from 'store/models';
import { useAppDispatch } from 'store/hooks';
import { setDisplaySelectListOptions } from 'store/slices/global';
import './SelectList.scss';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from 'services/constants';
import { useFrameChangeHeight } from 'Modulor/hooks';

interface SelectListOptionsProps {
  options: Option[];
  selectedOption: Option;
  setSelectValue: (option: Option) => void;
}

const MIN_OPTIONS_COUNT_FOR_SEARCH_FIELD = 15;

export const SelectListOptions = ({ options, selectedOption, setSelectValue }: SelectListOptionsProps) => {
  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const onSelectOption = (option: Option) => {
    dispatch(setDisplaySelectListOptions(null));
    setSelectValue(option);
  };

  const handleSearch = (value: string | FormEvent<HTMLDivElement>) => {
    if (typeof value === 'string') {
      setSearchTerm(value);
    }
  };

  const filteredOptions = options.filter((option) => (
    option?.label?.toLowerCase().includes(searchTerm.toLowerCase())));

  useFrameChangeHeight(!!filteredOptions.length);

  const displaySearchField = filteredOptions.length >= MIN_OPTIONS_COUNT_FOR_SEARCH_FIELD || searchTerm;

  return (
    <>
      {displaySearchField && (
        <div className="field-wrapper">
          <TextField
            placeholder={t(`${NAMESPACES.PW_KEYS}:PH.LISTCELL.SEARCH.TITLE`)}
            type="text"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
      )}
      <ListView className="list-wrapper">
        {!!filteredOptions.length && (
          filteredOptions.map((option: Option) => (
            <ListCell
              key={option.value}
              className="list-box"
              text={option.label}
              onClick={() => onSelectOption(option)}
              content={(
                <RadioGroup onChange={() => {}}>
                  <Radio
                    className="action__in-list-cell radio-btn"
                    value={option.value || ''}
                    checked={option.value === selectedOption?.value}
                  />
                </RadioGroup>
              )}
            />
          ))
        )}
      </ListView>
      {!filteredOptions.length && (
        <div className="empty-list-block">
          <Icon
            name="search"
            size={40}
            className="empty-list-block__icon"
          />
          <div className="empty-list-block__text">
            <Typography
              variant="title-2-semibold"
            >
              {t(`${NAMESPACES.PW_KEYS}:PH.LISTCELL.EMPTY.TITLE`)}
            </Typography>
          </div>
          <div className="empty-list-block__text">
            <Typography
              variant="body-regular"
            >
              {t(`${NAMESPACES.PW_KEYS}:PH.LISTCELL.EMPTY.DESCRIPTION`)}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
};
