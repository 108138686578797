import { FC, useEffect, useRef, useState } from 'react';
import { Button, Icon, Tooltip, Typography } from '@modulor/react';
import { displayTooltip, getTranslationMessage } from 'utils';
import { useOnClickOutside } from 'Modulor/hooks';
import { getFormattedAmountWithCurrency, getPrettierFormatNumber } from 'utils/dataTransform';
import clx from 'classnames';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';
import './TaxInfo.scss';
import { paymentFlowSelector } from 'store/slices/global';
import { useAppSelector } from 'store/hooks';
import { currencyDisplaySelector as depositCurrencyDisplaySelector } from 'store/slices/deposit';
import { currencyDisplaySelector as withdrawalCurrencyDisplaySelector } from 'store/slices/withdrawal';
import { FlowTypes } from 'store/constants';

export interface TaxInfoProps {
  taxValueAmount: number;
  percentTax?: number;
  isTaxHintShown: boolean;
  currency?: string;
  name?: string;
  description?: string;
  deductibleStakeAmount?: number;
}

export const TaxInfo: FC<TaxInfoProps> = (props) => {
  const {
    taxValueAmount,
    percentTax,
    isTaxHintShown,
    currency,
    name,
    description,
    deductibleStakeAmount,
  } = props;
  const { t } = useTranslation();
  const hintRef = useRef<HTMLDivElement | null>(null);
  const [isOpenedHint, showHint] = useState(false);
  const flowType = useAppSelector(paymentFlowSelector);
  const descriptionText = getTranslationMessage({
    t,
    value: description,
  });
  const nameText = getTranslationMessage({
    t,
    value: name,
  });

  const currencyDisplaySetting = useAppSelector(flowType === FlowTypes.deposit ? (
    depositCurrencyDisplaySelector
  ) : withdrawalCurrencyDisplaySelector);

  useEffect(() => {
    displayTooltip(false);
  }, []);

  useOnClickOutside(hintRef, () => showHint(false));

  if (!isTaxHintShown) {
    return null;
  }

  const toggleTaxInfoHint = () => {
    if (isOpenedHint) {
      showHint(false);
      displayTooltip(false);
      return;
    }
    displayTooltip(true);
    showHint(true);
  };

  const taxPercentValue = `${(percentTax && percentTax * 100) || 0}%`;
  return (
    <div
      className={clx('tax-info-block', {
        'hint-only': isTaxHintShown,
        'is-opened': isOpenedHint,
      })}
    >
      {deductibleStakeAmount && (
      <div className="deductible-amount">
        <Typography variant="subhead-regular">
          {t(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.TAX.DEDUCTIBLE_STAKE`)}
          :
        </Typography>
        <Typography
          variant="subhead-regular"
        >
          {`${getFormattedAmountWithCurrency(
            getPrettierFormatNumber(deductibleStakeAmount),
            currency,
            currencyDisplaySetting,
          )}`}
        </Typography>
      </div>
      )}
      <div className="tax-general-block">
        <div>
          <Typography
            variant="subhead-regular"
          >
            {
            t(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.TOOLTIP.TAX.TITLE`)
          }
          </Typography>
          {' '}
          <Typography variant="subhead-regular">
            {taxPercentValue}
          </Typography>
          <span
            ref={hintRef}
          >
            <Tooltip
              placement="bottom"
              show={isOpenedHint}
              className={clx('info-tooltip', {
                'hide-hint': !isTaxHintShown,
              })}
              heading={name ? nameText : t(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.TOOLTIP.TAX.TITLE`)}
              text={description ? descriptionText : t(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.TOOLTIP.TAX.BODY`)}
              button={(
                <Button
                  variant="primary"
                  className="info-tooltip-button"
                  onClick={toggleTaxInfoHint}
                >
                  {t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.ACCEPT`)}
                </Button>
                  )}
            >
              {[<Icon
                key="info-icon"
                onClick={toggleTaxInfoHint}
                className="info-icon"
                name="info_circle_outlined"
              />]}
            </Tooltip>
          </span>
        </div>
        <div className="tax-block-value">
          <Typography
            variant="subhead-regular"
          >
            {`${getFormattedAmountWithCurrency(
              getPrettierFormatNumber(taxValueAmount),
              currency,
              currencyDisplaySetting,
            )}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
