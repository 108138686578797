import { ForwardedRef, forwardRef } from 'react';
import clx from 'classnames';
import { Button, Icon, Loader, Typography } from '@modulor/react';
import { useTranslation } from 'react-i18next';
import { FlowTypes } from 'store/constants';
import { NAMESPACES } from 'services/constants';
import { useAppSelector } from 'store/hooks';
import { getPlatformsViewportSelector, isInPopUpSelector } from 'store/slices/global';
import IntegrationCommunication from 'services/integration-communication';
import CustomIframe from './CustomIframe';
import HiddenFields from './HiddenFields';
import { BodyTitle, FormContentProps } from './types';
import { getCustomFrameHeight, getMainFrameHeight } from './utils';

// eslint-disable-next-line react/display-name
export const FormContent = forwardRef((props: FormContentProps, ref: ForwardedRef<HTMLFormElement>) => {
  const {
    isInsideCustomIframe,
    shortMethodeName,
    handleSubmitForm,
    requestMethod,
    requestUrl,
    formTarget,
    requestFields,
    flowType,
    isFormHandled,
    isLLA,
    handleRedirectClick,
    isDeposit,
    handleGoBackClick,
  } = props;
  const { t } = useTranslation();
  const {
    modalHeight = 0,
    headerHeight = 0,
    navBarHeight = 0,
    height = 0,
    pageTop = 0,
  } = useAppSelector(getPlatformsViewportSelector) || {};
  const isOpenedInPopup = useAppSelector(isInPopUpSelector);

  if (isInsideCustomIframe) {
    const methodNameLowercase = shortMethodeName.toLowerCase();
    const mainFrameHeight = getMainFrameHeight({
      platformsViewport: {
        isOpenedInPopup,
        modalHeight,
        headerHeight,
        navBarHeight,
        height,
        pageTop,
      },
      methodName: methodNameLowercase,
      isOpenedInPopup,
    });

    IntegrationCommunication.sendMessage({
      id: 'frameChangedHeight',
      value: mainFrameHeight,
    });

    const customFrameHeight = getCustomFrameHeight({
      platformsViewport: {
        isOpenedInPopup,
        modalHeight,
        headerHeight,
        navBarHeight,
        height,
        pageTop,
      },
      methodName: methodNameLowercase,
      isOpenedInPopup,
    });

    return (
      <CustomIframe
        methodName={methodNameLowercase}
        customFrameHeight={customFrameHeight}
      >
        <section
          className={clx('submit-container', {
            fullWidth: true,
          })}
          style={{
            width: 'calc(100% - 32px)',
          }}
        >
          <div className="absolute-loader">
            <Loader />
          </div>
          <form
            onSubmit={handleSubmitForm}
            className="form"
            method={requestMethod}
            action={requestUrl}
            acceptCharset="utf-8"
            target={formTarget}
            ref={ref}
          >
            <HiddenFields
              requestFields={requestFields}
            />
          </form>
        </section>
      </CustomIframe>
    );
  }

  let bodyTitle: BodyTitle = {};
  if (flowType === FlowTypes.deposit) {
    const depositBodyTranslation = t(`${NAMESPACES.PW_KEYS}:PH.DEPOSIT.STATUS_PAGE.BODY.ALLOW_POPUPS`);
    const standartText = depositBodyTranslation.split('<0>')[0];
    const titleLinkText = depositBodyTranslation.split('<0>')[1];

    bodyTitle = {
      standartText,
      titleLinkText,
    };
  } else {
    bodyTitle = {
      standartText: t(`${NAMESPACES.PW_KEYS}:PH.WITHDRAWAL.STATUS_PAGE.BODY.ALLOW_POPUPS`),
    };
  }

  return (
    <section className="submit-container">
      <div className="submit-container__block">
        <Icon
          className="submit-container__info-link"
          name="info_circle_outlined"
          fill="icon-warning"
          size={40}
        />
        <Typography
          variant="title-2-semibold"
          style={{
            color: 'var(--content-title,var(--text-title))',
          }}
          className="submit-container__notice-sub-text"
        >
          {t(`${NAMESPACES.PW_KEYS}:PH.${flowType.toUpperCase()}.STATUS_PAGE.ALLOW_POPUPS.TITLE`)}
        </Typography>

        <form
          onSubmit={handleSubmitForm}
          className="form"
          method={requestMethod}
          action={requestUrl}
          acceptCharset="utf-8"
          target={formTarget}
          ref={ref}
        >
          <HiddenFields
            requestFields={requestFields}
          />
          {isFormHandled && !isLLA ? (
            <div id="submit-content">
              <Typography
                variant="body-regular"
                className="submit-container__notice-text"
              >
                <div>
                  {bodyTitle.standartText}
                </div>
                <Button
                  className="submit-container__submit-link"
                  role="link"
                  variant="text_button"
                  type="submit"
                  size="low"
                  onClick={handleRedirectClick}
                >
                  {bodyTitle.titleLinkText}
                </Button>
              </Typography>
            </div>
          ) : (
            <Loader />
          )}
        </form>
      </div>

      {!isLLA && isDeposit && (
        <div className="submit-container__button-container">
          <Button
            onClick={handleGoBackClick}
            type="button"
            variant="secondary"
          >
            {t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.CANCEL`)}
          </Button>
        </div>
      )}
    </section>
  );
});
