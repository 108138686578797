import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, FieldValues, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';
import { TextField } from '@modulor/react';
import { FieldType } from 'utils';
import { applyDateMask } from 'store/formatters/global';
import { NAMESPACES } from 'services/constants';
import { getErrorKey } from '../../fieldErrors';
import { DEFAULT_DATE_FORMAT } from './constants';

interface DateInputFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  isRequired?: boolean;
  placeHolder?: string;
  defaultValue: string | null;
  isDisabled?: boolean;
  validationMsgKey?: string;
  setValue: UseFormSetValue<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
}

export const DateInputField: FC<DateInputFieldProps> = (props) => {
  const {
    name,
    label,
    placeHolder = DEFAULT_DATE_FORMAT,
    control,
    isRequired,
    defaultValue,
    isDisabled = false,
    validationMsgKey,
    setValue,
    clearErrors,
  } = props;
  dayjs.extend(customParseFormat);
  const format = placeHolder || DEFAULT_DATE_FORMAT;
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const date = defaultValue && new Date(defaultValue);
    const dateRegexp = /^\d{4}-\d{2}-\d{2}$/;
    if (date
        && !Number.isNaN(date.getTime())
        && (dateRegexp.test(defaultValue) || defaultValue === new Date(defaultValue).toISOString())
    ) {
      const formattedValue = dayjs(date).format(format);
      setInputValue(formattedValue);
      setValue(name, date.toISOString());
      return;
    }
    setInputValue('');
    setValue(name, '', {
      shouldValidate: false,
      shouldDirty: false,
      shouldTouch: false,
    });
    setError(null);
    clearErrors(name);
  }, [defaultValue]);

  const handleChange = (value: string, field: FieldValues) => {
    const maskedValue = applyDateMask(value, format);
    setInputValue(maskedValue);

    const day = dayjs(maskedValue, format).date();
    const month = dayjs(maskedValue, format).month();
    const year = dayjs(maskedValue, format).year();

    if (!value) {
      setError(null);
      field.onChange('');
      return;
    }

    const isValid = dayjs(maskedValue, format, true).isValid();
    setError(isValid ? null : 'Invalid date');

    if (isValid) {
      const date = new Date(Date.UTC(year, month, day));
      field.onChange(date.toISOString());
    } else {
      field.onChange('');
    }
  };

  const fieldLabel = label || name ? t(`${NAMESPACES.PW_KEYS}:${label || name}`) : '';

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: isRequired,
      }}
      render={({ field, fieldState }: FieldValues) => {
        const errorMessageKey = getErrorKey(FieldType.input, fieldState, validationMsgKey);
        const errorMessage = error || t(errorMessageKey);

        return (
          <TextField
            {...field}
            leftIcon="calendar"
            name={name}
            placeholder={t(placeHolder)}
            error={errorMessage}
            label={fieldLabel}
            value={inputValue}
            disabled={isDisabled}
            readOnly={isDisabled}
            onChange={(value: string) => handleChange(value, field)}
          />
        );
      }}
    />
  );
};
