import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Typography } from '@modulor/react';
import { FieldValues, UseFormSetError, useForm } from 'react-hook-form';
import {
  Limits,
  MerchantUserFeeResponce,
  Parameter,
  PayHubEnumsCashierMethodParamType,
  PayHubCashierContractsViewDataResponse,
  PayHubWidgetContractsSubmitPaymentResponse,
  PayHubWidgetContractsSubmitPayoutResponse,
  ProcessingCurrency,
  Option,
} from 'store/models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { FlowTypes } from 'store/constants';
import { bonusDataSelector } from 'store/slices/bonus';
import { TaxContainer } from 'Modulor/components/Tax/TaxContainer';
import {
  useDetectNewErrors,
  useFrameChangeHeight,
  useGetTaxInfo,
  usePrefillAutosubmit,
} from 'Modulor/hooks';
import {
  currencyDisplaySelector,
  isSelfTargetSelector,
  methodNameSelector,
  setIsAutoSubmitted, setUploadedFile,
  uploadedFileFormDataSelector,
} from 'store/slices/deposit';
import {
  displaySelectListOptionsSelector,
  platformSelector,
  setAmount,
  setDisplaySelectListOptions,
} from 'store/slices/global';
import { getFormDefaultValues, getRequisiteFieldNameList, getSubmitButtonVariant } from 'utils';
import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from '@reduxjs/toolkit/dist/query';
import {
  Clickstream,
  useGetClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
  getClickStreamEvent,
} from 'services/clickstream';
import {
  CustomServerError,
  DepositSubmitRequestProps,
  useUploadDocFileForDepositMutation,
} from 'store/services/depositAPI';
import { isOTPEnabledSelector } from 'store/slices/otp';
import { fieldGetter, getAmountField } from 'Modulor/fieldGetter';
import { getFormattedAmountWithCurrency, getPrettierFormatNumber } from 'utils/dataTransform';
import { getGBFeatures } from 'services/growthbook';
import { NAMESPACES } from 'services/constants';
import { useTranslation } from 'react-i18next';
import { SelectListOptions } from 'Modulor/components/SelectList/SelectList';
import { getSubmitSubText, getUploadErrorMessage, getValidPresettedAmount } from './utils';
import { AmountBlocks } from '../components/AmountBlocks';
import { BonusInfo } from '../components/BonusInfo';
import { Fee } from '../components/Fee';
import { InfoLink } from '../components/InfoLink';
import { getAmountBlocksByExperiment } from '../components/utils';
import AdditionalInfo from '../components/AdditionalInfo';
import ModulorLoader from '../components/ModulorLoader';
import { ConversionBlock } from '../components/ConversionBlock';
import { ProcessingTime } from '../components/ProcessingTime';
import './DepositForm.scss';

interface DepositFormProps {
  amount: number;
  formConfig: Parameter[];
  amountBlocks?: number[];
  amountRegExpFe?: string;
  limits: Limits | null;
  submitCallback: (
    fields: FieldValues,
    setError: UseFormSetError<FieldValues>,
    dirtyFields: Partial<Record<keyof FieldValues, boolean>>,
  ) => void;
  merchantUserFee?: MerchantUserFeeResponce;
  currency: string;
  isFetchingSubmitRequest: boolean;
  presetCurrency?: string | null;
  isAutoSubmit?: boolean;
  isAutoSubmitSelectType?: boolean;
  isMethodLoaded?: boolean;
  submitDepositTrigger: LazyQueryTrigger<QueryDefinition<DepositSubmitRequestProps,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never, PayHubWidgetContractsSubmitPayoutResponse | PayHubWidgetContractsSubmitPaymentResponse, 'depositAPI'>>;
  payGroupId: number;
  additionalViewData?: PayHubCashierContractsViewDataResponse[] | null;
  fee?: string | null;
  presetAmount?: number | null;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  descriptionUrl?: string;
  processingTimeTranslationKey?: string;
}

const gbFeatures = getGBFeatures();

export const DepositForm: FC<DepositFormProps> = (props) => {
  const {
    amount,
    submitCallback,
    formConfig,
    amountBlocks,
    amountRegExpFe,
    limits,
    currency,
    isFetchingSubmitRequest,
    presetCurrency,
    isAutoSubmit,
    isAutoSubmitSelectType,
    isMethodLoaded,
    submitDepositTrigger,
    payGroupId,
    additionalViewData,
    merchantUserFee,
    fee,
    presetAmount,
    processingCurrency,
    processingCurrencyRate,
    descriptionUrl,
    processingTimeTranslationKey,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const gbAmountBlocksResult = gbFeatures.growthBook.evalFeature('billing.recommended.amount');
  const amountBlocksData = getAmountBlocksByExperiment({
    amountBlocks, limits, gbResult: gbAmountBlocksResult?.value,
  });
  const cashierContext = useGetClickStreamCashierContext(FlowTypes.deposit);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const displaySelectListOptions = useAppSelector(displaySelectListOptionsSelector);
  const bonusData = useAppSelector(bonusDataSelector);
  const isOTPEnabled = useAppSelector(isOTPEnabledSelector);
  const methodName = useAppSelector(methodNameSelector);
  const platform = useAppSelector(platformSelector);
  const isSelfTarget = useAppSelector(isSelfTargetSelector);
  const { t } = useTranslation();
  const currencyDisplaySetting = useAppSelector(currencyDisplaySelector);
  const currencyValue = presetCurrency || currency;
  const { bonusInfo, bonusByPayGroup, isGeneralBonus } = bonusData || {};
  const filteredFormConfig = isOTPEnabled ? formConfig.slice(0, -1) : formConfig;
  const requisiteFieldNameList = getRequisiteFieldNameList(formConfig);
  const defaultValues = getFormDefaultValues(formConfig);
  const isBonusIncluded = isGeneralBonus || Boolean(bonusByPayGroup?.includes(payGroupId));
  const isAutoSubmitPossible = useMemo(
    () => isAutoSubmit && !formConfig.some(({ isOtpRequisites }) => isOtpRequisites),
    [isAutoSubmit,
      isOTPEnabled],
  );

  const requiredFieldsName = useMemo(
    () => formConfig
      .filter((field) => field.isRequired)
      .map((field) => field.name),
    [formConfig],
  );
  const uploadedFileForA2aFlow = useAppSelector(uploadedFileFormDataSelector);
  const [triggerUpload, { isLoading: isUploadLoading,
    isError: isUploadError, error: uploadError }] = useUploadDocFileForDepositMutation();
  const isA2aFlow = formConfig.find((field) => field.type
      === PayHubEnumsCashierMethodParamType.FileUpload);
  const isSendUploadedDocError = uploadedFileForA2aFlow && isUploadError;
  const isDisabledByA2aFlow = useMemo(
    () => requiredFieldsName.includes('FileUpload')
          && ((isA2aFlow && !uploadedFileForA2aFlow) || !!isSendUploadedDocError || isUploadLoading),
    [uploadedFileForA2aFlow,
      isSendUploadedDocError,
      isUploadLoading],
  );

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    trigger,
    getValues,
    formState: {
      isValid,
      errors,
      dirtyFields,
    },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const { hasNewErrors } = useDetectNewErrors(errors);

  useFrameChangeHeight(hasNewErrors, {
    skip: !!displaySelectListOptions,
  });

  useEffect(() => {
    if (uploadedFileForA2aFlow) {
      const formData = new FormData();
      formData.append('receipt', uploadedFileForA2aFlow);

      triggerUpload({
        payGroupId,
        requestBody: formData,
      });
    }
  }, [uploadedFileForA2aFlow,
    payGroupId]);

  useEffect(() => {
    if (amount) {
      setValue('amount', amount, {
        shouldValidate: true,
      });
    }
  }, [amount]);

  useEffect(() => {
    if (isAutoSubmit && hasNewErrors) {
      dispatch(setIsAutoSubmitted(true));
    }
  }, [hasNewErrors]);

  useEffect(() => {
    const eventOpen = getClickStreamEvent.cashier_method_open;
    clickstream.push(eventOpen, [cashierContext,
      payGroupContext]);

    if (requisiteFieldNameList.length) {
      requisiteFieldNameList.forEach((name) => trigger(name));
    }

    return () => {
      dispatch(setUploadedFile(null));
      dispatch(setDisplaySelectListOptions(null));
    };
  }, []);

  const { tax, calculateNonTaxableAmount, amountWithTaxes } = useGetTaxInfo(
    amount,
    FlowTypes.deposit,
  );

  const showAmountBlocks = !!amountBlocksData?.length && !isAutoSubmit;
  const setAmountFromBlock = (amountFieldValue: number) => {
    dispatch(setAmount(`${amountFieldValue}`));
    setValue('amount', amountFieldValue, {
      shouldValidate: true,
    });
  };

  const amountField = getAmountField({
    amountRegExpFe,
    limits,
    currency: currencyValue,
    flowType: FlowTypes.deposit,
    isDisabled: isAutoSubmit,
    isAutoSubmitSelectType,
  });

  usePrefillAutosubmit({
    autoSubmit: isAutoSubmitPossible,
    isMethodLoaded,
    amountValue: getValidPresettedAmount({
      presetAmount: presetAmount || 0,
      amountBlocks,
      min: limits?.min,
      max: limits?.max,
    }),
    currency: currencyValue,
    payGroupId,
    isValid,
    submitDepositTrigger,
    validate: trigger,
    values: getValues(),
    formConfig,
    requiredFieldsName,
    onComplete: () => {
      setIsLoading(false);
    },
  });

  const newLocal = fieldGetter({
    t,
    setError,
    setValue,
    clearErrors,
    formField: {
      control,
    },
  })(amountField);

  const submitSubText = useMemo(() => getSubmitSubText({
    t,
    currency: currencyValue,
    amount,
    bonusInfo,
    isBonusIncluded,
    processingCurrency,
    processingCurrencyRate,
    merchantUserFee,
    fee,
    currencyDisplaySetting,
  }), [
    currency,
    amount,
    bonusInfo,
    isBonusIncluded,
    processingCurrency,
    processingCurrencyRate,
    merchantUserFee,
    fee,
    currencyDisplaySetting,
  ]);

  const shouldAutoSubmit = amount && isAutoSubmitPossible
      && !hasNewErrors && !formConfig.some((param) => param.isRequired);

  const renderFormContent = () => (
    <>
      {(shouldAutoSubmit || isLoading) && <ModulorLoader />}
      <div className="deposit-form__content">
        { bonusInfo && isBonusIncluded && (
          <BonusInfo {...bonusInfo} />
        )}
        {newLocal}
        {showAmountBlocks && (
          <AmountBlocks
            currentAmount={amount}
            amountBlocks={amountBlocksData}
            onClick={setAmountFromBlock}
            currency={currencyValue}
            isAutoSubmit={isAutoSubmitPossible}
          />
        )}
        <ProcessingTime message={processingTimeTranslationKey} />
        <Fee
          fee={fee}
          merchantUserFee={merchantUserFee}
        />
        <TaxContainer
          tax={tax}
          isTaxHintShown
          calculateNonTaxableAmount={calculateNonTaxableAmount}
          taxAmount={amountWithTaxes.taxAmount}
          amount={amount}
        />
        <ConversionBlock
          amount={amount}
          methodName={methodName}
          processingCurrency={processingCurrency}
          processingCurrencyRate={processingCurrencyRate}
        />
        {filteredFormConfig.map(
          fieldGetter({
            t,
            setError,
            setValue,
            clearErrors,
            formField: {
              control,
            },
            isLoading: isUploadLoading,
          }),
        )}
        {isA2aFlow && isSendUploadedDocError
            && (
            <Typography
              variant="caption-1-regular"
              style={{
                color: 'var(--text-error)',
              }}
            >
              {getUploadErrorMessage({
                errorMessage: uploadError && 'errorMessage' in uploadError
                  ? (uploadError as CustomServerError).errorMessage
                  : undefined,
                t,
              })}
            </Typography>
            )}
      </div>
      <div className="deposit-form__footer">
        <InfoLink
          methodName={methodName}
          platform={platform}
          descriptionUrl={descriptionUrl}
          isSelfTarget={isSelfTarget}
          flowType={FlowTypes.deposit}
        />
        <AdditionalInfo additionalData={additionalViewData} />
        <Button
          type="submit"
          disabled={!isValid || isDisabledByA2aFlow}
          loading={isFetchingSubmitRequest}
          variant={getSubmitButtonVariant(isValid)}
          doubleText={submitSubText}
        >
          {t(`${NAMESPACES.PW_KEYS}:PH.BUTTON.DEPOSIT_AMOUNT`, {
            amount: getFormattedAmountWithCurrency(
              getPrettierFormatNumber(amount),
              currencyValue,
              currencyDisplaySetting,
            ),
          })}
        </Button>
      </div>
    </>
  );

  const getSelectListOptionsList = (fields: Parameter[]) => {
    const selectField = displaySelectListOptions && fields.find((field) => (
      displaySelectListOptions && displaySelectListOptions[field.name]
    ));
    if (displaySelectListOptions && selectField?.options?.length) {
      return (
        <SelectListOptions
          options={selectField.options}
          selectedOption={getValues(selectField.name)}
          setSelectValue={async (option: Option) => {
            setValue(selectField.name, option);
            await trigger(selectField.name);
          }}
        />
      );
    }
    return null;
  };

  const selectListOptionsList = getSelectListOptionsList(filteredFormConfig);

  return (
    <form
      className="deposit-form"
      onSubmit={handleSubmit((fields) => submitCallback(fields, setError, dirtyFields))}
    >
      {(shouldAutoSubmit || isLoading) && <ModulorLoader />}
      <div className="deposit-form__content">
        {!displaySelectListOptions && renderFormContent()}
        {selectListOptionsList}
      </div>
    </form>
  );
};
