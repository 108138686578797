import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { parseQuery } from 'utils';
import { store } from 'store';

import DevView from './DevView';
import App from './App';
import IntegrationCommunication from './services/integration-communication';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const isWidgetPage = window.location.href.includes('deposit')
  || window.location.href.includes('withdrawal')
  || window.location.href.includes('checkout')
  || window.location.href.includes('tokenization_page')
  || window.location.href.includes('acc_management')
  || window.location.href.includes('3ds_result')
  || window.location.href.includes('payments');

const params = parseQuery(window.location.search);
const cashierInNewTab = params.cashier_in_new_tab;

const getRenderComponent = () => (
  <Provider store={store}>
    {process.env.NODE_ENV !== 'production' && !isWidgetPage ? <DevView /> : <App />}
  </Provider>
);

if (cashierInNewTab) {
  // remove query param to prevent looping (BL-88174)
  const frameUrl = window.location.href.replace('cashier_in_new_tab=true', '');

  IntegrationCommunication.sendMessage({
    id: 'redirectTo',
    value: frameUrl,
  });
} else {
  root.render(getRenderComponent());
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
